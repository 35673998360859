import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getAppVersion } from './CommonFunctions';
import { IconFacebook, IconTwitter, IconMemorialRed } from '../icon/index';

class Footer extends Component {
    state = {}
    render() {
        return (
            <React.Fragment>
                <footer>
                    <div className="container p-4">
                        <div className="d-md-flex align-items-center justify-content-between">
                            <div>
                                <ul className="list-inline list-link mb-3 mb-md-0">
                                    <li className="list-inline-item">
                                        <Link className="btn-link text-dark font-weight-medium mr-3" to="/terms-and-conditions">Terms & Conditions</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="btn-link text-dark font-weight-medium mr-3" to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="btn-link text-dark font-weight-medium mr-3" to="/gdpr">GDPR</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="btn-link text-dark font-weight-medium mr-3" to="/contact-us">Contact Us</Link>
                                    </li>
                                    <li className="list-inline-item">
                                        <a className="btn-link text-dark font-weight-medium mr-3" href="https://tickets.resumion.com/" target="_blank">Support Center</a>
                                    </li>
                                    <li className="list-inline-item">
                                        <Link className="btn-link text-dark font-weight-medium mr-3" to="/about-us">About Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <ul className="list-inline list-link m-0 footer-social-link">
                                    <li className="list-inline-item">
                                        <a target="_blank" rel="noopener noreferrer" className="btn-link text-dark rounded-pill d-block text-center mr-3 d-flex align-items-center justify-content-center" href="https://www.facebook.com/resumion/">
                                            <img src={IconFacebook} alt="facebook" height="30" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item">
                                        <a target="_blank" rel="noopener noreferrer" className="btn-link text-dark rounded-pill text-center d-block d-flex align-items-center justify-content-center" href="https://twitter.com/resumion">
                                            <img src={IconTwitter} alt="twitter" height="30" />
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>
                <div className="footer-bottom bg-dark">
                    <div className="container p-4">
                        <div className="d-md-flex align-items-center justify-content-between">
                            <div className='copy-right text-center text-md-left'>
                                <img className='icon-memorial' src={IconMemorialRed} alt="memorial" height="30px"/>
                                <p className="text-white d-md-inline d-block m-0 ml-2 mb-2 mt-2 mt-md-0">Resumion is made with ❤️ in Dhaka, BD <span className='small'>App version: {getAppVersion()}</span></p>
                                {/* <a className="btn btn-link text-white p-0" href="mailto:support@resumion.com">Help: support@resumion.com</a> */}
                            </div>
                            <div className='year text-center text-md-left'>
                                <span className="text-white mr-md-3">©{(new Date().getFullYear())} Resumion.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Footer;