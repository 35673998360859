import { FETCH_SKILLS } from '../actions/type';

const initialState = {
    skills: ''
};

export default function(state = initialState, actions){
    switch(actions.type){
        case FETCH_SKILLS:
            return {
                ...state,
                skills: actions.payload
            }
        default:
            return state;
    }
}

