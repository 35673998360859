import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from '../CommonFunctions';
import Footer from '../Footer';
import MetaTags from 'react-meta-tags';
import IconBadgePremium from '../../icon/badge-premium.svg';
import { connect } from 'react-redux';
import { isLoggedIn, getIsSubscribed } from '../../actions/CommonActions';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Promotion from '.././Banner/Promotion';
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class ResumeTemplates extends Component {
    state = { 
        packageId: '',
        productId: '',
        makepayment: false,
        templates: '',
    }
    componentDidMount() {
        removeLoaders();
        this.props.isLoggedIn();
        this.props.getIsSubscribed();
        // console.log(this.props.isLoggedIn());
        this.setState({
            ...this.state,
            userid: getUseridFromSession()
        })

        axios.get('https://resumion.com/apiv2/GetTemplate.php')
        .then(res => res.data.data)
        .then(res => {
            this.setState({
                templates: res[0].templates
            })
        });
    }
    handlePurchase = (packageId, productId) => {
        // console.log(packageId, productId)
        this.setState({
            ...this.state,
            packageId,
            productId,
            makepayment: true,
        })
        window.scrollTo(0, 0)
    }

    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(getUIDFromSession())
        this.handlePurchase = this.handlePurchase.bind(this);

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }

        if (this.state.templates !== '') {
            var template_list = this.state.templates.map(template => {
                return (
                    <div className='col-md-4' key={template.templateid}>
                        <div className='card rt mb-4'>
                            <div className='card-body p-0 position-relative'>
                            <img className='rh-thumbnail w-100' src={`https://resumion.com/images/${template.image}`} alt={template.templatename}/>



                            {/* this.props.is_subscribed === 1 && resume.premium === "1" || resume.premium === "0" ? */}
                            {console.log(this.props.is_subscribed)}
                            

                            {this.props.user_credentials.success === 1 ? (
                                this.props.is_subscribed === 1 ? (
                                    <Link to={`/create-rc?type=resume&templateid=${template.templateid}`} className="btn btn-primary rh-st animate__animated animate__slideInUp btn-rs position-absolute">Use This Template</Link>
                                ) : (
                                    <Link to={`/premium`} className="btn btn-primary rh-st animate__animated animate__slideInUp btn-rs position-absolute">Use This Template</Link>
                                )
                            ) : (
                                <Link to={`/builder?templateid=${template.templateid}`} className="btn btn-primary rh-st animate__animated animate__slideInUp btn-rs position-absolute">Use This Template</Link>
                                )
                            }


                            

                            {/* <Link className="">Use This Template</Link> */}
                            </div>
                            <div className='card-footer text-center bg-transparent border-0 pt-0 pb-4'>
                                <h5 className='mt-3 mb-1'>{template.templatename}</h5>
                                <span className={"badge " + (template.premium === '0' ? 'badge-secondary' : 'badge-tertiary')}>
                                    {template.premium === '0' ? 'Free' : 
                                        <>
                                            <img className="badge-premium d-inline" src={IconBadgePremium} alt="premium" /> Premium
                                        </>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                )
            })
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Resume Templates - Resumion</title>
                        <meta property="og:title" content="Pricing - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                    <Promotion/>
                    <div className="page-title pricing text-center mt-4 mt-md-5 mb-1 mb-md-4">
                        <h3 className='mb-0'>Resume Templates</h3>
                        <p className="text-secondary">
                            We have some elegant resume templates for you
                        </p>
                    </div>
                    <div className='bg-white pricing-table'>
                        <div className='container mb-5'>
                            <div className='on-floating'>
                                <div className='row mb-5'>
                                    {template_list}
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    is_subscribed: state.COM_REDU.is_subscribed,
})
export default connect(mapStateToProps, { isLoggedIn, getIsSubscribed })(ResumeTemplates);