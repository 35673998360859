// Feature removed proR
// export const getResumeidFromSession = () => {
//     const value = JSON.parse(localStorage.getItem("proR"))[0];
//     if (value?.length) {
//         let resumeid = JSON.parse(localStorage.getItem("proR"))[0];
//         return resumeid;
//     } else {
//     // handle null case
//     }
// }
import axios from 'axios';
import qs from 'querystring';



export const checkResumeTemplateAccess = (data) => {
    async function checkResume(resumeid) {
        try {
            const response = await axios.get(`https://resumion.com/apiv2/GetResumeCoverInfo.php?resumeid=${data}`);
            console.log(response.data.data[0].info[0]);
        } catch (error) {
            console.error(error);
        }
    }
    
    // Call checkResume with the resumeid from data
    checkResume(data.resumeid);
}



export const getURLSearchParams = (data) => {
    const query = new URLSearchParams(data);
    return query;
}

export const setFileDownload = (fileId) => {
    localStorage.setItem("fileDownload", true);
    localStorage.setItem("fileId", fileId);
}

export const getFileDownload = () => {
    const value = localStorage.getItem('fileDownload');
    if (value?.length) {
        let fileDownload = localStorage.getItem("fileDownload");
        return fileDownload;
    } else {
    // handle null case
    }
}

export const getFileId = () => {
    const value = localStorage.getItem('fileId');
    if (value?.length) {
        let fileId = localStorage.getItem("fileId");
        return fileId;
    } else {
    // handle null case
    }
}

export const proR = () => {
    const value = localStorage.getItem('proR');
    if (value?.length) {
        let proR = localStorage.getItem("proR");
        return proR;
    } else {
    // handle null case
    }
}

export const getAppVersion = () => {
    const value = localStorage.getItem('version');
    if (value?.length) {
        let version = localStorage.getItem("version");
        return version;
    } else {
    // handle null case
    }
}

export const setAppVersion = () => {
    // console.log('setAppVersion called')
    localStorage.setItem("version", JSON.stringify(20230907));
}

export const getUseridFromSession = () => {
    const value = JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user"))[2];
    if (value?.length) {
        let user = JSON.parse(localStorage.getItem("user"))[2];
        return user;
    } else {
    // handle null case
    }
}

export const getUIDFromSession = () => {
    const value = JSON.parse(localStorage.getItem("uid")) !== null && JSON.parse(localStorage.getItem("uid"))[0];
    if (value?.length) {
        let uid = JSON.parse(localStorage.getItem("uid"))[0];
        return uid;
    } else {
    // handle null case
    }
}

export const getCoverletteridFromSession = () => {
    const value = JSON.parse(localStorage.getItem("proC")) !== null && JSON.parse(localStorage.getItem("proC"))[0];
    if (value?.length) {
        let coverletterid = JSON.parse(localStorage.getItem("proC"))[0];
        return coverletterid;
    } else {
    // handle null case
    }
}

export const getIsEmailVerifiedFromSession = () => {
    const value = JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user"))[5];
    if (value?.length) {
        let isemailverified = JSON.parse(localStorage.getItem("user"))[5];
        return isemailverified;
    } else {
    // handle null case
    }
}

export const updateAvatarInSession = (avatar) => {
    localStorage.setItem("avatar", JSON.stringify([avatar]))
}

export const getAvatarFromSession = () => {
    const value = JSON.parse(localStorage.getItem("avatar")) !== null && JSON.parse(localStorage.getItem("avatar"))[0];
    if (value?.length) {
        let avatar = JSON.parse(localStorage.getItem("avatar"))[0];
        return avatar;
    } else {
    // handle null case
    }
}

export const removeLoaders = () => {
    function removeElement(element) {
        element && element.parentNode && element.parentNode.removeChild(element);
    }
    removeElement(document.getElementById('lds-ring'));
    removeElement(document.getElementById('loader-logo'));
}

// Updating user first_name and last_name in localStorage
export const updateUserCredentialsInSession = (first_name, last_name, title) => {
    const userid = JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user"))[2]
    const success = JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user"))[3]
    const isemailverified = JSON.parse(localStorage.getItem("user")) !== null && JSON.parse(localStorage.getItem("user"))[5]
    localStorage.setItem("user", JSON.stringify([first_name, last_name, userid, success, title, isemailverified]))
}

export const getMonth = (month) => {
    switch(month) {
        case "1":
            return 'January';
        case "2":
            return 'February';
        case "3":
            return 'March';
        case "4":
            return 'April';
        case "5":
            return 'May';
        case "6":
            return 'June';
        case "7":
            return 'July';
        case "8":
            return 'August';
        case "9":
            return 'September';
        case "10":
            return 'October';
        case "11":
            return 'November';
        case "12":
            return 'December';
        default:
            return '';
    }
}
// If value is null then return '-'
export const isNull = (data) => {
    if (data === null || data === "") {
        return '-';
    } else {
        return data;
    }
}

// Check input type number
export const onlyNumber = (event) => {
    {if (!/[0-9]/.test(event.key)) {event.preventDefault();}}
}
