import React, { Component } from 'react';
import { PulseLoader } from 'react-spinners';

class PageContentLoader extends Component {
    state = {
        loading: true
    }
    render() {
        return (
            <div className="m-auto">
                <div className='sweet-loading'>
                    <PulseLoader
                        sizeUnit={"px"}
                        size={10}
                        color={'#0078D7'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default PageContentLoader;