/* eslint-disable import/no-anonymous-default-export */
import {
    FETCH_USER_LOGIN,
    CALL_LOADER,
    IS_LOGGEDIN,
    RETRY_LOGIN,
    IS_AUTHENTICATED,
    LOGOUT_USER,
    SEND_REG_USER_DATA,
    REMOVE_PAGE_LOADER,
    VIEW_CONTROLLER,
    GET_JOB_CATEGORY_LIST,
    GET_AVATAR,
    GET_STATUS_SHARE_RESUME,
    GET_PRODUCT_LIST,
    GET_IS_SUBSCRIBED,
    UPDATE_DASHBOARD_TAB,
} from '../actions/type';

const initialState = {
    user_credentials: {
        success: '',
        data: {
            first_name: '',
            last_name: '',
            userid: ''
        }
    },
    is_authenticated: '',
    btn_controller: {
        btn_loader: '2',
        is_disabled: ''
    },
    page_loader: true,
    view_controller: {
        personal_details_view: true,
        personal_details_edit: false
    },
    job_category_list: '',
    avatar: '',
    status_share_resume: '',
    product_list: '',
    is_subscribed: null,
    is_subscribed_validity: null,
    dashboard_tab_active: 1
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case CALL_LOADER:
            return {
                ...state,
                btn_controller: actions.payload
            };
        case REMOVE_PAGE_LOADER:
            return {
                ...state,
                page_loader: actions.payload
            };
        case FETCH_USER_LOGIN:
            return {
                ...state,
                user_credentials: actions.payload,
                btn_controller: {
                    btn_loader: '2',
                    is_disabled: ''
                }
                // is_authenticated: true
            };
        case GET_AVATAR:
            return {
                ...state,
                avatar: actions.payload
            };
        case RETRY_LOGIN:
            return {
                ...state,
                user_credentials: actions.payload
            };
        case SEND_REG_USER_DATA:
            return {
                ...state,
                user_credentials: {
                    ...state.user_credentials,
                    success: actions.payload[3],
                    data: {
                        first_name: actions.payload[0],
                        last_name: actions.payload[1],
                        userid: actions.payload[2]
                    }
                }
            };
        case IS_LOGGEDIN:
            return {
                ...state,
                user_credentials: {
                    ...state.user_credentials,
                    success: actions.payload[0][3],
                    data: {
                        first_name: actions.payload[0][0],
                        last_name: actions.payload[0][1],
                        userid: actions.payload[0][2],
                        title: actions.payload[0][4],
                        verified: actions.payload[0][5],
                        // resumeid: actions.payload[1][0],
                        // coverletterid: actions.payload[2][0],
                        // avatar: actions.payload[4]
                    }
                }
            };
        case IS_AUTHENTICATED:
            return {
                ...state,
                is_authenticated: actions.payload
            };
        case LOGOUT_USER:
            return {
                ...state,
                user_credentials: {
                    success: null
                },
                is_authenticated: false,
                resume_list: '',
                coverletter_list: '',
            };
        case GET_JOB_CATEGORY_LIST:
            return {
                ...state,
                job_category_list: actions.payload
            };
        case GET_STATUS_SHARE_RESUME:
            return {
                ...state,
                status_share_resume: actions.payload
            };
        case GET_PRODUCT_LIST:
            return {
                ...state,
                // product_list: {
                //     resume_list: actions.payload[0],
                //     coverletter_list: actions.payload[1],
                // }
                resume_list: actions.payload[0],
                coverletter_list: actions.payload[1],
            }

        case GET_IS_SUBSCRIBED:
            return {
                ...state,
                is_subscribed: actions.payload[0],
                is_subscribed_validity: actions.payload[1],
            }

        case UPDATE_DASHBOARD_TAB:
            return {
                ...state,
                dashboard_tab_active: actions.payload,
            }
            
        default:
            return state;
    }
}
