import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from '../CommonFunctions';
import Footer from '../Footer';
import MetaTags from 'react-meta-tags';
import {
    IconCheckBgSmall,
    IconStarBgSmall,
    IconAccessCircle,
    IconTicketsCircle,
    IconWalletCircle,
} from '../../icon/index';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../actions/CommonActions';
import { Link } from 'react-router-dom';

class CoverLetterTemplates extends Component {
    state = { 
        packageId: '',
        productId: '',
        makepayment: false,
    }
    componentDidMount() {
        removeLoaders();
        this.props.isLoggedIn();
        // console.log(this.props.isLoggedIn());
        this.setState({
            ...this.state,
            userid: getUseridFromSession()
        })
    }
    handlePurchase = (packageId, productId) => {
        // console.log(packageId, productId)
        this.setState({
            ...this.state,
            packageId,
            productId,
            makepayment: true,
        })
        window.scrollTo(0, 0)
    }

    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(getUIDFromSession())
        this.handlePurchase = this.handlePurchase.bind(this);

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Pricing - Resumion</title>
                        <meta property="og:title" content="Pricing - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                
                    <div className="page-title pricing text-center mt-5 mb-4">
                        <h3>Resume builders for everyone</h3>
                        <p className="text-secondary">
                            Let’s pick your sutable package
                        </p>
                    </div>
                    <div className='bg-white pricing-table'>
                        <div className='container mb-5'>
                            <div className='on-floating'>
                                <div className='row mb-5'>
                                    <div className='col-md-3'>
                                        <div className='card pricing'>
                                            <div className='card-title text-center mb-0'>
                                                <h2 className='price-amount'>$2.99</h2>
                                                <span className='plan'>7 days trail subscription</span>
                                            </div>
                                            <div className='card-body pl-4 pr-4 pb-0 pt-4'>
                                                <ul>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> Unlimited resumes and cover letters</li>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> All premium templates and colors</li>
                                                    <li><img src={IconStarBgSmall} alt="icon"/> Unlimited PDF downloads</li>
                                                </ul>
                                            </div>
                                            <div className='card-footer bg-transparent text-center border-0 pb-4'>
                                                {success_redux !== 1 && session_success !== 1 ? (
                                                    <Link to='/builder' className='btn btn-primary btn-sm'>Builder Resume</Link>
                                                ) : (
                                                    <button to='/make-payment' className='btn btn-primary btn-sm' onClick={() => this.handlePurchase(1, 751160)}>Upgrade Now</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='card pricing'>
                                            <div className='card-title text-center mb-0'>
                                                <h2 className='price-amount'>$19.99</h2>
                                                <span className='plan'>3 months subscription</span>
                                            </div>
                                            <div className='card-body pl-4 pr-4 pb-0 pt-4'>
                                                <ul>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> Unlimited resumes and cover letters</li>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> All premium templates and colors</li>
                                                    <li><img src={IconStarBgSmall} alt="icon"/> Unlimited PDF downloads</li>
                                                </ul>
                                            </div>
                                            <div className='card-footer bg-transparent text-center border-0 pb-4'>
                                                {success_redux !== 1 && session_success !== 1 ? (
                                                    <Link to='/builder' className='btn btn-primary btn-sm'>Builder Resume</Link>
                                                ) : (
                                                    <button className='btn btn-primary btn-sm' onClick={() => this.handlePurchase(2, 762281)}>Upgrade Now</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='card pricing'>
                                            <div className='card-title text-center mb-0'>
                                                <h2 className='price-amount'>$34.99</h2>
                                                <span className='plan'>6 months subscription</span>
                                            </div>
                                            <div className='card-body pl-4 pr-4 pb-0 pt-4'>
                                                <ul>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> Unlimited resumes and cover letters</li>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> All premium templates and colors</li>
                                                    <li><img src={IconStarBgSmall} alt="icon"/> Unlimited PDF downloads</li>
                                                </ul>
                                            </div>
                                            <div className='card-footer bg-transparent text-center border-0 pb-4'>
                                                {success_redux !== 1 && session_success !== 1 ? (
                                                    <Link to='/builder' className='btn btn-primary btn-sm'>Builder Resume</Link>
                                                ) : (
                                                    <button className='btn btn-primary btn-sm' onClick={() => this.handlePurchase(3, 762282)}>Upgrade Now</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-3'>
                                        <div className='card pricing'>
                                            <div className='card-title text-center mb-0'>
                                                <h2 className='price-amount'>$45.99</h2>
                                                <span className='plan'>12 months subscription</span>
                                            </div>
                                            <div className='card-body pl-4 pr-4 pb-0 pt-4'>
                                                <ul>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> Unlimited resumes and cover letters</li>
                                                    <li><img src={IconCheckBgSmall} alt="icon"/> All premium templates and colors</li>
                                                    <li><img src={IconStarBgSmall} alt="icon"/> Unlimited PDF downloads</li>
                                                </ul>
                                            </div>
                                            <div className='card-footer bg-transparent text-center border-0 pb-4'>
                                                {success_redux !== 1 && session_success !== 1 ? (
                                                    <Link to='/builder' className='btn btn-primary btn-sm'>Builder Resume</Link>
                                                ) : (
                                                    <button className='btn btn-primary btn-sm' onClick={() => this.handlePurchase(4, 762283)}>Upgrade Now</button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row package-feature'>
                                    <div className='col-md-4 d-flex'>
                                        <div className='mr-3'>
                                            <img src={IconWalletCircle} alt="icon"/>
                                        </div>
                                        <div>
                                            <h5 className='mb-2'>30-day money-back guarantee</h5>
                                            <p className='small'>Joining Resumion.com is risk-free. Cancel your subscription within 30 days and get a full refund. No questions asked.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 d-flex'>
                                        <div className='mr-3'>
                                            <img src={IconAccessCircle} alt="icon"/>
                                        </div>
                                        <div>
                                            <h5 className='mb-2'>Unlimited Access</h5>
                                            <p className='small'>Edit, Update, Share your resume with anybodies for unlimited number of times. No restractions.</p>
                                        </div>
                                    </div>
                                    <div className='col-md-4 d-flex'>
                                        <div className='mr-3'>
                                            <img src={IconTicketsCircle} alt="icon"/>
                                        </div>
                                        <div>
                                            <h5 className='mb-2'>Support team that cares</h5>
                                            <p className='small'>Your opinion is important to us. Contact us. Our supporters and developers work hand in hand to help you.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg-grey payment-ins'>
                        <div className='container'>
                            <div className='col-md-6 offset-md-3 text-center'>
                                
                                <p className='small mb-1'>
                                    We accept all major payment methods and process payments with Paddle SSL Secure / 256-bit SSL secure checkout. 30-Day Money Back Guarantee.
                                </p>
                                <span className='text-2x-small'>
                                    Cards (including Mastercard, Visa, Maestro, American Express, Discover, Diners Club, JCB, UnionPay, and Mada) PayPal Alipay (in Early Access)* iDEAL* Google Pay (Chrome only)* Apple Pay (HTTPS pages on Safari only)* Wire Transfers (ACH/SEPA/BACS)*
                                </span>
                            </div>
                        </div>
                    </div>

                    <div className="bg-white faq">
                        <div className="container">
                            <div className="page-title text-center mb-4">
                                <h3 className="text-center">Pricing FAQ</h3>
                                <p className="text-secondary">
                                    If you don't see an answer to your question, <br/>
                                    you can send us an email from our contact form.
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <div className="col-md-8">
                                    <div className="accordion" id="accordionFaq">
                                        <div className="card mb-3">
                                            <div className="card-header border-0" id="headingOne">
                                                <h5 className="mb-0">
                                                    <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                        1. How can I use resumion.com for free?
                                                    </a>
                                                </h5>
                                            </div>

                                            <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionFaq">
                                                <div className="card-body pt-0">
                                                    <strong>Ans:</strong> Resumion.com has a few different tools you can use 100% for free without entering any credit card details.
                                                    <ul className="list-unstyled mt-2 ml-3">
                                                        <li>a. Create your account.</li>
                                                        <li>b. Fill up your basic information.</li>
                                                        <li>c. Download, share, print our basic resume template for free.</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mb-3">
                                            <div className="card-header border-0" id="headingTwo">
                                                <h5 className="mb-0">
                                                    <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                        2. How do I create my resume?
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionFaq">
                                                <div className="card-body pt-0">
                                                <strong>Ans:</strong> First create an account and a basic resume is automatically created with your minimal information. Then you will be redirected to fill up your rest of the information. Also you can fill up later after login from your dashboard.
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card mb-3">
                                            <div className="card-header border-0" id="headingThree">
                                                <h4 className="mb-0">
                                                    <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                        3. How  can I download or print or preview my resume?
                                                    </a>
                                                </h4>
                                            </div>
                                            <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionFaq">
                                                <div className="card-body pt-0">
                                                <strong>Ans:</strong> Login to your account and on the top menu portion you’ll find dashboard link.Go to dashboard and you’ll find download, print and preview option for free templates.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card mb-3">
                                            <div className="card-header border-0" id="headingFour">
                                                <h5 className="mb-0">
                                                    <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                                        4. Can I share my resume to social media?
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseFour" className="collapse" aria-labelledby="headingFour" data-parent="#accordionFaq">
                                                <div className="card-body pt-0">
                                                <strong>Ans:</strong> You can share your CV to different social media from “My Resume” portion in the dashboard, same place your can download, print and preview as well.
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card">
                                            <div className="card-header border-0" id="headingFive">
                                                <h5 className="mb-0">
                                                    <a className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseThree">
                                                        5. Can I create a shareable public link?
                                                    </a>
                                                </h5>
                                            </div>
                                            <div id="collapseFive" className="collapse" aria-labelledby="headingFive" data-parent="#accordionFaq">
                                                <div className="card-body pt-0">
                                                <strong>Ans:</strong> Yes, when you go to “My Resume” portion from dashboard, you’ll find an option share. After clicking share, an auto generated link will be provided to you which is a shareable public link for you.
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
})
export default connect(mapStateToProps, { isLoggedIn })(CoverLetterTemplates);