import React, { Component } from 'react';
import { css } from '@emotion/core';
// First way to import
import { BarLoader } from 'react-spinners';
// Another way to import. This is recommended to reduce bundle size
// import ClipLoader from 'react-spinners/ClipLoader';

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
`;
class PageLoader extends Component {
    state = {
        loading: true
    }
    render() {
        return (
            <div className="PageLoaderContainer w-100 h-100 bg-light d-flex align-items-center position-fixed">
                <div className="m-auto">
                    <div className='sweet-loading'>
                        <BarLoader
                            css={override}
                            sizeUnit={"px"}
                            size={150}
                            color={'#0078D7'}
                            loading={this.state.loading}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default PageLoader;