import React, { Component } from 'react';
import { 
    IconPencilWhite,
    IconDownloadWhite,
    IconAngleLeftWhite,
} from '../../icon/index';
import { NavLink } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import { removeLoaders, getURLSearchParams, getUseridFromSession } from '../CommonFunctions';
import '../../../src/css/resume_preview.css';
import history from '../../history';
import { connect } from 'react-redux';
import { Document, Page } from 'react-pdf';
// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class PreviewCoverLetter extends Component {
    state = {
        preview_resume: false,
        // print_preview: true,
        userid: '',
        pageNumber: 1,
        numPages: null,
        coverid: '',
    }

    componentDidMount() {
        removeLoaders();

        let query = getURLSearchParams(this.props.location.search)
        const coverid = query.get('id')
        this.setState({
            coverid
        })
    }
    
    handleBack = (e) => {
        e.preventDefault();
        history.goBack();
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            pageNumber: numPages
        })
    }

    render() {

        this.handleBack = this.handleBack.bind(this);
        let URL = `https://resumion.com/apiv2/DownloadCoverLetter.php?coverletterid=${this.state.coverid}&userid=${getUseridFromSession()}&actiontype=preview`;
        // console.log(URL)
        return (
            <React.Fragment>
                <header className="preview-panel bg-grey position-absolute w-100">
                    <div className="d-flex action-bar justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                    <button onClick={this.handleBack} className="btn btn-back text-white d-flex align-items-center"><img className="mr-3" src={IconAngleLeftWhite} alt="back" height="18"/>Back</button>
                    <div className="action-btn-group">
                        <React.Fragment>
                            <ReactTooltip/>
                            <NavLink className="btn btn-primary p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Edit" data-type="light" to={`/cover-letter?id=${this.state.coverid}`}>
                                <img src={IconPencilWhite} alt="edit" height="24"/>
                            </NavLink>
                            
                            <a rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadCoverLetter.php?coverletterid=${this.state.coverid}&userid=${getUseridFromSession()}&actiontype=download`} className="btn btn-success p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Download" data-type="light">
                                <img src={IconDownloadWhite} alt="download" height="24"/>
                            </a>
                        </React.Fragment>
                        </div>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <div className="preview-container mb-5 rounded-lg">
                                {this.props.match.params.coverid !== '' &&
                                    <Document 
                                        loading="Loading cover letter..."
                                        renderMode="svg"
                                        file={URL}
                                        onLoadSuccess={this.onDocumentLoadSuccess}>
                                        {Array.from(
                                            new Array(this.state.pageNumber),
                                            (el, index) => (
                                            <Page
                                                className="d-flex justify-content-center mb-4"
                                                scale={1.3}
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                renderTextLayer={false}
                                            />
                                            ),
                                        )}
                                    </Document>
                                }
                            </div>
                        </div>
                    </div>
                </header>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    product_list: state.COM_REDU.product_list
})

export default connect(mapStateToProps, null)(PreviewCoverLetter);
