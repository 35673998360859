import { FETCH_REGFORMDATA, FETCH_CHECKEMAIL, FETCH_PERSONALDETAILS, SEND_REG_USER_DATA } from '../actions/type';

const initialState = {
    user_credentials: '',
    checkemail: '',
    personal_details: '',
    educations: ''
};

export default function (state = initialState, actions) {
    switch (actions.type) {
        case FETCH_REGFORMDATA:
            return {
                ...state,
                regformdata: actions.payload
            };
        case FETCH_CHECKEMAIL:
            return {
                ...state,
                checkemail: actions.payload
            };
        case FETCH_PERSONALDETAILS:
            return {
                ...state,
                personal_details: actions.payload
            };
        case SEND_REG_USER_DATA:
            return {
                ...state,
                user_credentials: actions.payload
            }
        default:
            return state;
    }
}