import React, { Component } from 'react';
import { FadeLoader } from 'react-spinners';

class SpinnerLoader extends Component {
    state = {
        loading: true
    }
    render() {
        return (
            <div className='d-flex justify-content-center'>
                <div className='sweet-loading'>
                    <FadeLoader
                        sizeUnit={"px"}
                        size={15}
                        margin={2}
                        color={'#0078D7'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default SpinnerLoader;