import React, { Component } from 'react';
import { removeLoaders, getUIDFromSession } from '.././CommonFunctions';
import MetaTags from 'react-meta-tags';

class MakePayment extends Component {
    state = { 
        uid: ''
    }
    componentDidMount() {
        removeLoaders();
        this.setState({
            ...this.state,
            uid: getUIDFromSession()
        })
    }

    
    
    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(this.state.uid)
        // console.log(this.props.packageId)
        // console.log(this.props.productId)

        let paymentURL = `https://resumion.com/payment/makepayment.php?userid=${getUIDFromSession()}&package=${this.props.packageId}&product=${this.props.productId}`
        // let paymentURL = `https://phonerdam.com/payment/makepayment.php?userid=1&package=1&product=${this.props.product}`
        // console.log(paymentURL)
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Payment - Resumion</title>
                        <meta property="og:title" content="Subscription - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2">
                            <iframe className='w-100 iframe-big border-0 mt-4 mb-5' src={paymentURL} title="description"></iframe>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default MakePayment;