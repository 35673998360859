import React, { Component } from 'react';
import { removeLoaders } from './CommonFunctions';
import Footer from './Footer';
import MetaTags from 'react-meta-tags';

class PrivacyPolicy extends Component {
    componentDidMount() {
        removeLoaders();
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Privacy Policy - Resumion</title>
                        <meta property="og:title" content="Privacy Policy - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="page-title text-center mb-4 mt-5">
                    <h3 className="text-center">Privacy Policy</h3>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className="d-flex justify-content-center mb-5">
                            <div className="col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        <h5 className="mb-3">1. Errorstation.com</h5>
                                        <p>
                                            Errorstation.com B.V. (‘Errorstation.com’) and its associated companies are Bangladeshi companies. We store our data on servers in the United States. We process your personal data when you use our services, applications, websites and software. This is referred to as the “Service” below. In this privacy statement we provide a concise description of when and how we collect, use and secure your personal data.
                                        </p>
                                        <h5 className="mb-3">2. General</h5>
                                        <p>
                                            The provisions of this privacy statement may be amended. We will let you know when we do so. However, we advise you to check for yourself from time to time whether the privacy statement has been changed.
                                        </p>
                                        <h5 className="mb-3">3. What personal information do we collect?</h5>
                                        <p>
                                            There are various ways in which we can collect your personal data. In this section we explain which personal information we can collect from you. The personal data is sorted according to the different processing objective. For each objective it is indicated how long the personal data are stored for the relevant purpose. If any changes occur in statutory retention periods, these will apply to the retention periods mentioned in this privacy statement.
                                        </p>
                                        <p>
                                            Personal data directly collected by us or directly provided by you to us.
                                        </p>
                                        <p>
                                            3.1 Processing data pursuant to a statutory basis
                                        </p>
                                        <p>
                                        <strong>1 Administration obligations:</strong> we retain these personal data for 10 years maximum
                                        </p>
                                        <ul>
                                            <li>your name</li>
                                            <li>your address</li>
                                            <li>your VAT identification number</li>
                                            <li>invoice and payment details</li>
                                        </ul>
                                        <p>
                                            These personal data must be provided to us if you wish to use our Services. The reason is that we need these data to fulfil our statutory obligations.
                                        </p>
                                        <p>
                                        3.2 Processes that are necessary for the execution of an agreement concluded by you (providing the Service)
                                        </p>
                                        <p>
                                        <strong>1. General:</strong> we keep this personal data up to 5 years after the end of the agreement (before we delete your data we will ask you if you should still want to keep your account any longer).
                                        </p>
                                        <ul>
                                            <li>name</li>
                                            <li>date of birth</li>
                                            <li>gender</li>
                                            <li>private and/or business e-mail address</li>
                                            <li>telephone number</li>
                                            <li>employer</li>
                                            <li>work-related position</li>
                                            <li>education</li>
                                            <li>aspects of interest in career development</li>
                                            <li>(intended) job applications</li>
                                            <li>password (encrypted)</li>
                                            <li>session ID</li>
                                            <li>bank/payment details</li>
                                            <li>photos uploaded by you</li>
                                            <li>personal data entered by you when contacting us</li>
                                        </ul>
                                        <p>
                                        If you wish to use our Service, you must provide us with your name, e-mail address and payment details. The reason is that these personal data are required to be able to provide the Service to you. You can provide the other personal data mentioned above in your use of the Service. However, you are not obliged to provide these personal data to us.
                                        </p>
                                        <p>
                                        3.3 Processing in order to look after our legitimate interests
                                        </p>
                                        <p>
                                            1. Improvement of our services: we keep these personal data for a maximum of 2 years after they have been collected
                                        </p>
                                        <ul>
                                            <li>IP address</li>
                                            <li>device ID</li>
                                            <li>user ID</li>
                                            <li>operating system</li>
                                            <li>login time, date and place</li>
                                            <li>how you handle our website and Service</li>
                                        </ul>
                                        <p>
                                        1. Keeping the Service safe and preventing abuse: We keep these personal data for 1 year after they have been collected (if we discover abuse, we may keep these personal data for an indefinite period of time to protect our Service.)
                                        </p>
                                        <ul>
                                            <li>IP address</li>
                                            <li>device ID</li>
                                            <li>session ID</li>
                                            <li>operating system</li>
                                            <li>login time, date and place</li>
                                            <li>payment location</li>
                                            <li>type of connection</li>
                                        </ul>
                                        <p>
                                            1. In order to inform you about our other services (if you have purchased a paid service from us): we keep these personal data until you state that you no longer wish to receive marketing information
                                        </p>
                                        <ul>
                                            <li>e-mail address (if you enter this in the appropriate field)</li>
                                        </ul>
                                        <p>
                                        We process this personal data on the basis of an evaluation of interests. If you do not want to provide the data under 3.3.1 or 3.3.2, we ask you to let us know by stating your reasons. We will consider your reasons and re-evaluate the interests. If, after a new evaluation of interests, we come to the conclusion that you still need to provide your personal data, you will not be able to use our Service if you refuse to provide such personal data. More information about your rights can be found below under the heading ‘Your rights’.
                                        </p>
                                        <p>
                                        You can unsubscribe yourself from the processing mentioned under 3.3.3 by following the unsubscribe instructions that are included with each marketing e-mail. If you unsubscribe, this will not affect our ability to send you important e-mails about the Service and your account. In addition, it does not affect our ability to use your personal data as described in this privacy statement.
                                        </p>
                                        <p>
                                        3.4 Processing with your permission
                                        </p>
                                        <p>
                                            1. To inform you (at your request) about the service you have followed or other services offered by us: we keep these personal data until you let us know that you no longer wish to receive marketing information
                                        </p>
                                        <ul>
                                            <li>name</li>
                                            <li>organisation</li>
                                            <li>e-mail address</li>
                                        </ul>
                                        <p>
                                        1. In order to inform you at your request and to answer your questions: we keep this personal data for a maximum of 2 months after your request or question has been processed
                                        </p>
                                        <ul>
                                        name
                                        organisation
                                        e-mail address
                                        other personal data entered by you when contacting us
                                        </ul>
                                        <p>
                                        Tracking cookies
                                        </p>
                                        <ul>
                                            <li>online/cookie ID number</li>
                                            <li>IP address</li>
                                            <li>device ID</li>
                                            <li>pixel ID</li>
                                            <li>your use of our website</li>
                                            <li>The web page from which you originate</li>
                                            <li>your location</li>
                                            <li>how you use our Service</li>
                                            <li>what purchases you make</li>
                                            <li>which advertisements you have seen</li>
                                        </ul>
                                        <p>
                                        You are not obliged to provide these personal data to us. If you do not provide the personal data to us, this will not have any negative consequences for your use of the Service. You can therefore continue to use the Service. We only process these personal data if you have given permission for processing and they will only be processed once you have effectively given your consent or provided the personal data by yourself.
                                        </p>
                                        <h5 className="mb-3">4. Sharing your personal data</h5>
                                        <p>
                                        Unless otherwise stated in this privacy statement, we do not describe, sell or trade any personal data about our visitors and users to third parties.
                                        </p>
                                        <p>
                                            <strong>4.1 Sharing with processors</strong>
                                        </p>
                                        <p>
                                        We may ask others to assist with the provision of the Service. Such third parties may process your personal data. These third parties are referred to as “Processor” in this privacy statement. We conclude processing agreements with these processors.
                                        </p>
                                        <p>
                                        We use the following types of Processors:
                                        </p>
                                        <ul>
                                            <li>developers and suppliers of software;</li>
                                            <li>storage of (personal) data and database management and maintenance;</li>
                                            <li>research agencies and analytical software to improve our services and website (including privacy-friendly Google Analytics settings to prevent sharing personal data with Google);</li>
                                            <li>ticket managers for customer support;</li>
                                            <li>managers of evaluation forms;</li>
                                            <li>hosting provider(s);</li>
                                            <li>providers of relationship-management software.</li>
                                        </ul>
                                        <p>
                                        In some cases, the Processor may collect your personal data on our behalf. We inform the Processors that they may only use personal data obtained from us to enable the provision of the Service. Processors may not use this information for advertisements.
                                        </p>
                                        <p>
                                        We bear no responsibility if you provide additional information to these processors by yourself. It will be useful to be properly advised about the Processor and his company before you provide your personal data.
                                        </p>
                                        <p>
                                            <strong>4.2 Sharing with your permission</strong>
                                        </p>
                                        <p>
                                        We may moreover share personal data with others if you give us permission to do so. For example, we can work with other parties to offer you specific services or offers. If you register for these services or marketing offers, we may provide your name or contact details if necessary for providing such service or for contacting you.
                                        </p>
                                        <p>
                                            <strong>4.3 Our legal responsibility</strong>
                                        </p>
                                        <p>
                                        We may further share personal data with third parties if this is:
                                        </p>
                                        <ul>
                                            <li>reasonably necessary or appropriate to fulfil legal obligations;</li>
                                            <li>is necessary to fulfil legal requests from authorities;</li>
                                            <li>is required to respond to any claims;</li>
                                            <li>is necessary to protect our rights, property or safety as well as that of our users, our employees or the public;</li>
                                            <li>is necessary to protect ourselves or our users against fraudulent, abusive, inappropriate or unlawful use of the Service.</li>
                                        </ul>

                                        <p>
                                            We will immediately inform you if a government agency makes a request that relates to your personal data, unless we are not allowed to do so pursuant to the law.
                                        </p>
                                        <p>
                                            <strong>4.4 Merger or sale of (part of) the company</strong>
                                        </p>
                                        <p>
                                        Your personal data may be disclosed, shared or transferred in case we transfer part of our business. Examples include (negotiations about) a merger, sale of parts of the company or in order to obtain financing. We will obviously try to limit the impact for you as much as possible by transferring personal data only if necessary.
                                        </p>
                                        
                                        <h5 className="mb-3">5. Protection of personal data</h5>
                                        <p>
                                        We believe it is important to treat your personal details with due care and to protect them. We have therefore taken appropriate technical and organisational security measures for the protection of your personal data. We have at any rate taken the following measures:
                                        </p>

                                        <ul>
                                            <li>We have implemented physical and electronic measures designed to prevent unauthorised access, loss or misuse of personal data as much as possible.</li>
                                            <li>We use SSL/TLS (Secure Socket Layer/Transport Layer Security) technology where necessary to encrypt transmission of sensitive information or personal data to us, such as account passwords and other payment-related identifiable information.</li>
                                            <li>Two-step authentication is used where reasonably possible in order to prevent unauthorised access to personal data to the greatest extent.</li>
                                            <li>Where reasonably possible, backups of personal data are made.Sensitive information is stored encrypted wherever possible.</li>
                                            <li>Vulnerabilities in the software are dealt with as quickly as reasonably possible.</li>
                                        </ul>
                                        <p>
                                        We would like to point out that absolute security for the transmission of personal data via the internet or the storage of personal data cannot at all times be guaranteed.
                                        </p>

                                        <h5 className="mb-3">6. Links to third-party sites</h5>
                                        <p>
                                        Our Services may contain links to other websites and services. In addition, our Service may moreover be provided with third-party advertisements. Third-party websites and services may keep information about you. We have no control of these sites or their activities. We have no involvement if you provide your personal data to third parties. In that case, the privacy policy of such third party applies. We bear no responsibility with regard to the content of the privacy policy of such parties and the way in which these parties deal with personal data. We encourage you to review their privacy and security practices and policies before you provide personal information to them.
                                        </p>
                                        <h5 className="mb-3">7. Your rights</h5>
                                        <p>
                                        You may check, update, correct or delete the personal data collected by us. You can also request us to limit the processing. You can furthermore ask us to transfer the personal information we process about you to another person. You must in that case indicate which information you wish to have transferred. We comply with such a request if it is related to information processed on the basis of article 3.2 or 3.4 of this privacy statement, unless this information also contains personal data about other persons.
                                        </p>
                                        
                                        <p>
                                        In order to use of your rights you must make use of the possibilities we offer in your online environment. You can contact us if the online environment does not work or you are unable to exercise your rights there. Our contact details can be found in article 8 of this privacy statement. Obviously you may also contact the Service itself if it provides the possibility to contact us.
                                        </p>

                                        <p>
                                        We will review your request to verify that the legal requirements have been met and to confirm that your request does not violate our legitimate interests. For example, we have a legitimate interest if we need the personal data to be able to provide our Service to others, to resolve disputes, to maintain the applicable terms of use, for technical and/or legal requirements and/or if the Service or law so requires.
                                        </p>
                                        <p>
                                        In order to access your personal data by email, you must provide sufficient proof of your identity in the manner requested. If we do not know for sure that you are the person who is related to the relevant personal data, we may refuse you access to the personal data.
                                        </p>
                                        <p>
                                        We will respond to all your requests as described in this statement within 4 weeks. In case of complex requests we may extend the period by another 4 weeks. If we extend the period, we will notify you within 4 weeks after submission of the request.
                                        </p>
                                        <h5 className="mb-3">8. Contact</h5>
                                        <p>
                                        If you have any questions, issues or comments about this privacy statement, please contact us via e-mail support@Resumion.com or by post.
                                        </p>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default PrivacyPolicy;