import React, { Component } from 'react';
import { ClipLoader } from 'react-spinners';

class CircleLoader extends Component {
    state = {
        loading: true
    }
    render() {
        return (
            <div className='d-flex justify-content-center'>
                <div className='sweet-loading'>
                    <ClipLoader
                        size={20}
                        color={'#0078D7'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default CircleLoader;