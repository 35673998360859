import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchRegFormData, fetchCheckEmail } from '../actions/RegFormActions';
import { isLoggedIn, sendRegUserData } from '../actions/CommonActions';
import { Link } from 'react-router-dom';
import PageLoader from '../PageLoader';
import RegSuccess from '../components/RegSuccess';
import axios from 'axios';
import qs from 'querystring';
import { removeLoaders, getFileDownload, getFileId } from './CommonFunctions';
import { CountryDropdown } from 'react-country-region-selector';
import { Redirect } from "react-router-dom";
import Footer from './Footer';
import { Line, Circle } from 'rc-progress';
import LayoutLoader from '../LayoutLoader';
import MetaTags from 'react-meta-tags';
import Slider from "react-slick";
import IconBadgePremium from '../icon/badge-premium.svg';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class RegForms extends Component {
    state = {
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        password: '',
        address: '',
        country: '',
        city: '',
        category_list: '',
        job_category: '',
        regFormState: 1,
        isvalid: 'form-control',
        templateid: '',
        isDisabledPrevBtn: 'disabled',
        isDisabled: 'disabled',
        isDisabledCategories: 'disabled',
        progress: 0,
        page_loader: false,
        templates: '',
        redirect: '',
        checked_terms: false,
        checked_newsletters: false,
        terms: '',
        newsletters: 0,
        emailexists: '',
    }

    // componetDidUpdate() {
    //     this.props.sendRegUserData(this.state);
    // }
    componentWillMount() {
        this.setState({
            ...this.state
        })
    }
    componentDidMount() {
        removeLoaders();
        this.props.isLoggedIn();

        axios.get('https://resumion.com/apiv2/GetTemplate.php')
        .then(res => res.data.data)
        .then(res => {
            this.setState({
                templates: res[0].templates
            })
        });

        // console.log(this.props.location.search.slice(1))
        const query = new URLSearchParams(this.props.location.search);
        const templateid = query.get('templateid')
        // console.log(templateid)

        templateid !== '' && templateid !== null && (
            this.setState({
                templateid,
                regFormState: 2,
                progress: 20
            })
        )
        




    }
    componentDidUpdate(prevProps, prevState) {
        if(prevState.redirect === '') {
            const success_redux = this.props.user_credentials.success
            if (localStorage.getItem("user") !== null) {
                // Get success value from session storage
                var session_success = JSON.parse(localStorage.getItem("user"))[3]
            }
            if (success_redux !== 1 && session_success !== 1) {
                this.setState({
                    redirect: true
                })
            }
            // if(this.state.regFormState === 3 && prevState.emailexists === null){
            //     this.setState({
            //         emailexists: this.props.checkemail
            //     })
            // }
        }
    }
    handleCheckboxTerms = () => {
        if(this.state.checked_terms){
            this.setState({
                checked_terms: false, 
                terms: 0
            }, ()=>this.checkJobCategory())
        }
        if(this.state.checked_terms === false){
            this.setState({
                checked_terms: true,
                terms: 1
            }, ()=>this.checkJobCategory())
        }
    }
    handleCheckboxNewsletters = () => {
        if(this.state.checked_newsletters){
            this.setState({
                checked_newsletters: false,
                newsletters: 0
            }, ()=>this.checkJobCategory())
        }
        if(this.state.checked_newsletters === false){
            this.setState({
                checked_newsletters: true,
                newsletters: 1
            }, ()=>this.checkJobCategory())
        }
    }
    handleChangeFname = (e) => {
        this.setState({
            first_name: e.target.value
        }, () => {
            this.checkNameLength()
        })
    }

    handleChangeLname = (e) => {
        this.setState({
            last_name: e.target.value
        }, () => {
            this.checkNameLength()
        })
    }

    handleChangeEmail = (e) => {
        this.setState({
            email: e.target.value
        }, () => {
            // this.props.fetchCheckEmail(this.state.email)
        })
    }

    handleBlur = (e) => {
        const data = {
            email: this.state.email
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/CheckEmail.php', qs.stringify(data), config)
            .then(res => res.data.success)
            .then(res => {
                this.setState({
                    emailexists: res
                }, ()=> {
                    this.checkEmailPhonePassLength();
                })
            })
    }

    handleChangePhone = (e) => {
        this.setState({
            phone: e.target.value
        }, () => {
            this.state.regFormState === 3 &&
            this.checkEmailPhonePassLength();
        })

    }
    handleChangePassword = (e) => {
        this.setState({
            password: e.target.value
        }, () => this.checkEmailPhonePassLength())
    }
    handleChangeAddress = (e) => {
        this.setState({
            address: e.target.value
        }, () => this.checkAddressCountryCity())
    }

    handleChangeCountry = (value) => {
        this.setState({
            country: value
        }, () => this.checkAddressCountryCity())
    }

    handleChangeCity = (e) => {
        this.setState({
            city: e.target.value
        }, () => this.checkAddressCountryCity())
    }

    handleChangeJobCategory = (e) => {
        this.setState({
            job_category: e.target.value
        }, () => this.checkJobCategory())
    }

    selectTemplate = (e) => {
        e.preventDefault();
        const { regFormState } = this.state;
        console.log(e.target.id);
        this.checkNameLength();
        
        regFormState === 1 &&
        this.setState({
            ...this.state,
            regFormState: 2,
            templateid: e.target.id,
            progress: 25
        })
        
    }
    handleBack = (e) => {
        e.preventDefault();
        const { regFormState } = this.state
        
            regFormState == 2 &&
                this.setState({
                    ...this.state,
                    regFormState: regFormState - 1,
                    progress: 0,
                    // isDisabledPrevBtn: 'disabled'
                })
        
        
            regFormState === 3 &&
                this.setState({
                    ...this.state,
                    regFormState: regFormState - 1,
                    progress: 25,
                    // isDisabledPrevBtn: 'disabled'
                }, () => this.checkNameLength())
        
        
            regFormState === 4 &&
                this.setState({
                    ...this.state,
                    regFormState: regFormState - 1,
                    progress: 50,
                }, () => this.checkEmailPhonePassLength())
        
        
            regFormState === 5 &&
                this.setState({
                    ...this.state,
                    regFormState: regFormState - 1,
                    progress: 75
                }, () => this.checkAddressCountryCity())
        
        // console.log(this.state)
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { first_name, last_name, email, phone, address, country, city, job_category, regFormState } = this.state;
        
        regFormState === 1 &&
            this.setState({
                ...this.state,
                first_name: first_name,
                last_name: last_name,
                regFormState: 2,
                progress: 25
            }, () => this.checkNameLength())
        
        
        regFormState === 2 &&
            this.setState({
                ...this.state,
                email: email,
                phone: phone,
                regFormState: 3,
                progress: 50,
                // isDisabledPrevBtn: ''
            }, () => this.checkEmailPhonePassLength())
    
    
        regFormState === 3 &&
            this.setState({
                ...this.state,
                address: address,
                country: country,
                city: city,
                regFormState: 4,
                isDisabled: 'disabled',
                progress: 75,
                // isDisabledPrevBtn: ''
            }, () => this.checkAddressCountryCity())

            axios.get('https://resumion.com/apiv2/GetJobCategories.php')
            .then(res => res.data.data[0])
            .then(res => {
                this.setState({
                    ...this.state,
                    category_list: res,
                    isDisabledCategories: ''
                })
            })
    
    
        regFormState === 4 &&
            this.setState({
                ...this.state,
                job_category: job_category,
                regFormState: 5,
                isDisabled: 'disabled',
                progress: 100,
                // isDisabledPrevBtn: ''
            }, () => this.checkJobCategory())
        
    
    
        regFormState === 5 &&
            this.setState({
                ...this.state,
                page_loader: true,
                regFormState: 6,
                // isDisabledPrevBtn: ''
            }, () => this.props.sendRegUserData(this.state))
    }

    // Checking the length of each forms
    checkNameLength = () => {
        const fnameLength = this.state.first_name.length;
        const lnameLength = this.state.last_name.length;
        
        lnameLength === 0 || fnameLength === 0 ?
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            }) : (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            )
        
        // console.log(this.state.regFormState)
    }

    checkEmailPhonePassLength = () => {
        const emailLength = this.state.email.length;
        const phoneLength = this.state.phone.length;
        const passwordLength = this.state.password.length;
        const emailexists = this.state.emailexists;
        
        emailLength === 0 || phoneLength === 0 || passwordLength <= 5 || emailexists === 1 ?
            this.setState({
                isDisabled: 'disabled'
            }) : (
                this.setState({
                    isDisabled: ''
                })
            )
    }

    checkAddressCountryCity = () => {
        const addressLength = this.state.address.length;
        const countryLength = this.state.country.length;
        const cityLength = this.state.city.length;
        
        addressLength === 0 || countryLength === 0 || cityLength === 0 ?
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            }) : (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            )
    }
    checkJobCategory = () => {
        const jobcategoryLength = this.state.job_category.length;
        const checked_terms = this.state.checked_terms;
        
        jobcategoryLength === 0 || checked_terms === false ?
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            }) : (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            )
    }
    render() {
        // console.log(this.props.user_credentials);
        // console.log(this.state)
        // console.log(this.props.checkemail);
        this.selectTemplate = this.selectTemplate.bind(this);

        var settingsTemplates = {
            className: "center",
            centerMode: true,
            infinite: true,
            centerPadding: "60px",
            slidesToShow: 3,
            speed: 500,
            arrows: true,
            lazyLoad: true,
            responsive: [
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                  }
                }
              ]
        };
        

        if (this.state.templates !== '') {
            var template_list = this.state.templates.map(template => {
                return (

                    <div className='rh-container' key={template.templateid}>
                        <img className='rh-thumbnail' src={`https://resumion.com/images/${template.image}`} alt={template.templatename}/>
                        <button onClick={this.selectTemplate} id={template.templateid} className="btn btn-primary rh-st animate__animated animate__slideInUp">Use This Template</button>
                        <div className='rh-info'>
                            <h5 className='mt-3 mb-1'>{template.templatename}</h5>
                            <span className={"badge " + (template.premium === '0' ? 'badge-secondary' : 'badge-tertiary')}>
                                {template.premium === '0' ? 'Free' : 
                                    <>
                                        <img className="badge-premium d-inline" src={IconBadgePremium} alt="premium" /> Premium
                                    </>
                                }
                            </span>
                        </div>
                    </div>
                )
            })
        }

        if (this.state.category_list !== '') {
            var category_list = this.state.category_list.category.map(category => {
                return (
                    <option key={category.id} value={category.id}>{category.category_name}</option>
                )
            })
        }

        const {
            first_name,
            last_name,
            email,
            phone,
            password,
            address,
            country,
            city,
            jobcategory,
            regFormState,
            page_loader,
            progress,
            isDisabledPrevBtn,
            isDisabled,
        } = this.state;

        // console.log(this.state);
        // console.log(this.props.user_credentials);

        const success_redux = this.props.user_credentials.success

        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }


        if (success_redux == 1 && session_success == 1) {
            return (
                <Redirect to='/dashboard' />
            )
        }


        return (

            <React.Fragment>
                <div>

                    {/* {regFormState === 6 && this.props.user_credentials.success === 1 &&
                        <Redirect to='/dashboard' />
                    } */}
                    {regFormState === 6 && this.props.user_credentials.success === 1 &&
                        (
                            getFileDownload() === 'true' ? (
                                <Redirect to={`/free-files?id=${getFileId()}`} />
                            ) : (
                                <Redirect to='/dashboard' />
                            )
                        )
                    }
                    
                    {regFormState === 6 && this.props.user_credentials.success === null &&
                        <PageLoader />
                    }
                    <MetaTags>
                        <title>Create Account - Resumion</title>
                        <meta property="og:title" content="Create Account - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                    


                    <div className="d-flex justify-content-center mb-4 mb-md-5">
                        <div className="col-sm-6 col-lg-4">
                            <div className="d-flex justify-content-center">
                                <div className="col-sm-12 col-lg-8">
                                    {regFormState !== 6 &&
                                        <React.Fragment>
                                            <Line className="mt-5 d-block" percent={progress} strokeWidth="3" strokeColor="#0078D7" trailWidth="3" trailColor="rgba(0, 105, 189, 0.1)" />
                                        </React.Fragment>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    {regFormState !== 6 &&
                        <form onSubmit={this.handleSubmit.bind(this)}>
                            {regFormState === 1 &&
                                <div className="container">
                                <div className="text-center resume-slider p-0">
                                    <h5 className="steps-title animated fadeInDown text-center mt-0 mb-2">Step 1: Select template</h5>
                                    <Slider {...settingsTemplates}>
                                        {template_list}
                                    </Slider>
                                </div>
                            </div>
                            }

                            <div className="d-flex justify-content-center mb-4 mb-md-5">
                                <div className="col-sm-6 col-lg-4">
                                    <div className="d-flex justify-content-center">
                                        <div className="col-sm-12 col-lg-8">
                                            {regFormState === 2 &&
                                                <div>
                                                    <h5 className="steps-title animated fadeInDown text-center mt-0 mb-5">Step 2: Your name</h5>
                                                    <div className="form-group">
                                                        <label htmlFor="fast_name">First name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChangeFname.bind(this)} value={first_name} type="text" className="form-control" name="fName" placeholder="e.g. John" required />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="lName">Last name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChangeLname.bind(this)} value={last_name} type="text" className="form-control" name="lName" placeholder="e.g. Doe" required />
                                                    </div>
                                                </div>
                                            }
                                            {regFormState === 3 &&
                                                <div>
                                                    <h5 className="steps-title animated fadeInDown text-center mt-0 mb-5">Step 3: Contact information & Password</h5>
                                                    <div className="form-group">
                                                        <label htmlFor="fName">Email Address <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onBlur={this.handleBlur.bind(this)} onChange={this.handleChangeEmail.bind(this)} value={email} type="email" id="email" className={"form-control " + (this.state.emailexists === 1 ? 'is-invalid' : null)} name="email" placeholder="name@email.com" required />
                                                        {this.state.emailexists === 1 &&
                                                            <small id="fNameHelp" className="form-text invalid-feedback">This email address is already used. <Link to="/login">Login</Link></small>
                                                        }
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone">Phone Number <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChangePhone.bind(this)} value={phone} type="number" className="form-control" name="phone" placeholder="e.g. 12025550191" required />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="phone">Password
                                                            <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p>
                                                            <small className="form-text text-muted">Min 6 characters</small>
                                                        </label>
                                                        <input onChange={this.handleChangePassword.bind(this)} value={password} type="password" className="form-control" name="password" placeholder="xxxxxxxxx" required />

                                                    </div>
                                                </div>
                                            }
                                            {regFormState === 4 &&
                                                <div>
                                                    <h5 className="steps-title animated fadeInDown text-center mt-0 mb-5">Step 4: Your address</h5>
                                                    <div className="form-group">
                                                        <label htmlFor="address">Address <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChangeAddress.bind(this)} value={address} type="text" className="form-control" name="address" placeholder="e.g. NY City 1B Road" />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="country">Country <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <CountryDropdown className="form-control" name="country" value={country} onChange={(value) => this.handleChangeCountry(value)} />
                                                    </div>
                                                    <div className="form-group">
                                                        <label htmlFor="city">City <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <input onChange={this.handleChangeCity.bind(this)} value={city} type="text" className="form-control" name="city" placeholder="e.g. New York, NY" />
                                                    </div>
                                                </div>
                                            }
                                            {regFormState === 5 &&
                                                <div>
                                                    <h5 className="steps-title animated fadeInDown text-center mt-0 mb-5">Step 5: Select job category</h5>
                                                    <div className="form-group">
                                                        <label htmlFor="jobcategory">Job Category <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                        <select onChange={this.handleChangeJobCategory.bind(this)} className="form-control" name="jobcategory" disabled={this.state.isDisabledCategories}>
                                                            <option value="">Select</option>
                                                            {category_list}
                                                        </select>
                                                    </div>
                                                    <div className="form-group form-check pl-0 mb-2">
                                                        <input onChange={this.handleCheckboxTerms.bind(this)} checked={this.state.checked_terms} type="checkbox" className="form-check-input" id="terms"/>
                                                        <label className="form-check-label" htmlFor="terms">I agreed with the <a target="_new" href="/privacy-policy">Privacy Policy</a> and <a target="_new" href="/terms-and-conditions">Terms & Conditions</a></label>
                                                    </div>
                                                    <div className="form-group form-check pl-0 mb-2">
                                                        <input onChange={this.handleCheckboxNewsletters.bind(this)} checked={this.state.checked_newsletters} type="checkbox" className="form-check-input" id="newsletters"/>
                                                        <label className="form-check-label" htmlFor="newsletters">I want to subscribe to the newsletter</label>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {regFormState !== 1 && regFormState !== 6 &&
                                                <div className="d-flex justify-content-between">
                                                    <button onClick={this.handleBack.bind(this)} type="button" className="btn btn-outline-dark btn-lg mr-2 w-50">Back</button>
                                                    <button type="submit" className="btn btn-primary btn-lg ml-2 w-50" disabled={isDisabled}>Next</button>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    }


                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    // Getting data from common reducers for similar check, here is the login check
    checkemail: state.REG_FORM_REDU.checkemail,
    user_credentials: state.COM_REDU.user_credentials
})

export default connect(mapStateToProps, { fetchRegFormData, fetchCheckEmail, sendRegUserData, isLoggedIn })(RegForms);