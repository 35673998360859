import React, { Component } from 'react';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import EmptyState from '../../components/EmptyState'
import { IconGlobe, IconTrash, IconPlusCircleWhite } from '../../icon/index';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class Languages extends Component {
    state = {
        languages: null,
        view: true,
        edit: false,
        add_new: false,
        language_name: '',
        level: '',
        resumeid: null,
        layout_loader: true,
        isDisabled: 'disabled',
        // language_edit_now: null,
        language_name_edit: null,
        language_id_edit: null,
        language_level_edit: null,
        btn_loader: false
    }


    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.languages === null && this.props.languages !== null) {
            this.setState({
                ...this.state,
                languages: this.props.languages
            })
            if (prevState.layout_loader === true) {
                this.setState({
                    ...this.state,
                    layout_loader: false
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }

    }



    addSkill = (e) => {
        e.preventDefault();
        this.props.insertEmploymentForm();
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkNameAndLevel())
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkNameAndLevelEdit())
    }
    checkNameAndLevel = () => {
        {
            this.state.language_name !== null && this.state.language_name.length !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )

        }
        // console.log(this.state.skill_name.length)
    }

    checkNameAndLevelEdit = () => {
        {
            this.state.language_name_edit !== null && this.state.language_name_edit.length !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )

        }
        // console.log(this.state.skill_name.length)
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    handleAddSkill = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true,
            level: 1
        }, () => this.endBtnLoader())
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false,
            skill_name: ''
        }, () => this.checkNameAndLevel())
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            language_name: this.state.language_name,
            level: this.state.level
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddLanguage.php', qs.stringify(data), config)
            .then(res => res.data.data[0].language[0])
            .then(res => this.setState({
                ...this.state,
                view: true,
                edit: false,
                add_new: false,
                language_name: '',
                label: '',
                languages: [...this.state.languages, res]
            }), () => this.checkNameAndLevel())
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const data = {
            id: this.state.language_id_edit,
            language_name: this.state.language_name_edit,
            level: this.state.language_level_edit
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetLanguage.php', qs.stringify(data), config)
            .then(res => res.data.data[0].language[0])
            // .then(res => console.log(res))
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.languages.map(language => language.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let languages = [...this.state.languages];
                let language = { ...languages[indices] };
                language = res;
                languages[indices] = language;
                this.setState({
                    view: true,
                    edit: false,
                    languages: languages
                });
                this.notify();
                this.endBtnLoader();
            })
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'language'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res.data))
                    .then(() => this.notifyDelete())
                const languages = this.state.languages.filter(language => language.id !== id);
                this.setState({ languages: languages });
            }
        })
    }

    handleEdit = (id) => {
        // console.log(id)
        const languages = this.state.languages.filter(language => language.id === id);
        this.setState({
            view: false,
            edit: true,
            language_name_edit: languages[0].language_name,
            language_id_edit: languages[0].id,
            language_level_edit: languages[0].level,
        }, () => this.checkNameAndLevelEdit())
    }
    checkValue = (value) => {
        // if(value === "3"){
        //     return 'asdfsadfsadfsdf'
        // }
        switch (value) {
            case "1":
                return 'Beginner';
                break;
            case "2":
                return 'Competent';
                break;
            case "3":
                return 'Intermediate';
                break;
            case "4":
                return 'Advanced';
                break;
            case "5":
                return 'Expert';
                break;
            default:
                break;
        }
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAddSkill = this.handleAddSkill.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        // this.handleEdit = this.handleEdit.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);

        // console.log(this.state)
        // console.log(this.props.languages)

        if (this.state.languages !== null) {
            var languages = this.state.languages.map(language => {
                return (
                    <div className="col-md-6" key={language.id}>
                        <div className="d-flex align-items-center">
                            <div className="flex-fill">
                                <h6 className="m-0">{language.language_name}</h6>
                            </div>
                            <div className="flex-fill text-right">
                                <button onClick={this.handleEdit.bind(this, language.id)} className="btn btn-link-edit p-0 mr-2"><img src={IconEdit} alt="edit" height="20"/></button>
                                <button onClick={this.handleDelete.bind(this, language.id)} className="btn btn-link-delete p-0"><img src={IconTrashSolid} alt="delete" height="20"/></button>
                            </div>
                        </div>
                        <p className='small text-black-3'>{this.checkValue(language.level)}</p>
                        <div className="w-100">
                            <div className="progress progress-s-1 mt-2 mb-4">
                                <div className={"progress-bar w-" + language.level * 20} role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                )
            })
        }
        // console.log(this.state.languages)

        return (
            <div className="card mb-4">
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconGlobe} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">Languages</h5>
                                        <p className="text-muted mb-0">Add your native and other communicative languages.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.languages !== null &&
                                    <React.Fragment>
                                        <div className="row">
                                            {languages}
                                            {this.state.languages !== null && this.state.languages.length === 0 &&
                                                <div className="col text-center">
                                                    <EmptyState />
                                                    <button onClick={this.handleAddSkill} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                                </div>
                                            }
                                        </div>
                                        {this.state.languages !== null && this.state.languages.length !== 0 &&
                                            <button onClick={this.handleAddSkill} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="language_name_edit">Languages  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="language_name_edit" placeholder="e.g. English" value={this.state.language_name_edit} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="language_level_edit">Level  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <select className="form-control" name="language_level_edit" onChange={this.handleChangeEdit} value={this.state.language_level_edit} required>
                                                    <option value="1">Beginner</option>
                                                    <option value="2">Competent</option>
                                                    <option value="3">Intermediate</option>
                                                    <option value="4">Advanced</option>
                                                    <option value="5">Expert</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="language_name">Languages  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="language_name" placeholder="e.g. English" value={this.state.language_name} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="level">Level  <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <select className="form-control" name="level" onChange={this.handleChange} value={this.state.level}>
                                                    <option value="1">Beginner</option>
                                                    <option value="2">Competent</option>
                                                    <option value="3">Intermediate</option>
                                                    <option value="4">Advanced</option>
                                                    <option value="5">Expert</option>
                                                </select>
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(Languages);