import React, { Component } from 'react';
import { removeLoaders } from '../CommonFunctions';

class NotFound extends Component {
    componentDidMount() {
        removeLoaders();
    }
    render() { 
        return (
           <div className='d-flex justify-content-center p-5'>
                :/ Not Found
           </div>
        );
    }
}
 
export default NotFound;