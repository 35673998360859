import React, { Component } from 'react';
import { removeLoaders, getUIDFromSession } from './CommonFunctions';
import { Link, Redirect } from "react-router-dom";
import BtnLoader from '../BtnLoader';
import Footer from './Footer';
import axios from 'axios';
import qs from 'querystring';
import MetaTags from 'react-meta-tags';
import SpinnerLoader from '../../src/Loader/SpinnerLoader';
import { isLoggedIn } from '../actions/CommonActions';
import { connect } from 'react-redux';

import {
    IconPDFSolidBlack
} from '../icon/index';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class Subscription extends Component {
    state = { 
        uid: null,
        SubscriptionStatus: 0,
        SubscriptionInfo: [],
        SubscriptionInvoiceStatus: 0,
        SubscriptionInvoice: [],
        totalInvoice: '',
    }

    componentDidMount() {
        removeLoaders();
        this.setState({
            uid: getUIDFromSession()
        })
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.userid !== null && prevState.SubscriptionInfo.length === 0) {
            axios.get('https://resumion.com/apiv2/SubscriptionInfo.php?uid='+this.state.uid)
            .then(res => 
                // console.log(res.data.data[0]),
                this.setState({ 
                    SubscriptionInfo: res.data.data[0].SubscriptionInfo[0],
                    SubscriptionStatus: res.data.subscription
                })
            )
        }

        if (prevState.userid !== null && prevState.SubscriptionInvoice.length === 0) {
            axios.get('https://resumion.com/apiv2/SubscriptionInvoice.php?uid='+this.state.uid)
            .then(res => 
                // console.log(res.data.data.length)
                res.data.data.length > 0 &&
                this.setState({ 
                    SubscriptionInvoice: res.data.data[0].invoice,
                    SubscriptionInvoiceStatus: res.data.subscription
                })
            )
        }
    }
    
    render() { 
        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Subscription - Resumion</title>
                        <meta property="og:title" content="Subscription - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="page-title text-center mt-5 mb-4">
                    <h3>Subscription</h3>
                    <p className="text-secondary">
                        Manage and monitor your subscription
                    </p>
                </div>
                <div className='container'>
                    <div className='row'>
                        <div className="col-md-8 offset-md-2">
                            <div className='row d-flex align-items-stretch mb-4'>
                                <div className='col-12'>
                                    <div className="card">
                                            {/* {console.log(this.state.SubscriptionInfo.length )} */}
                                            {this.state.SubscriptionInfo.length !== 0 ? (
                                                <>
                                                <div className="card-body d-flex justify-content-between align-items-center">
                                                    <div>
                                                        <h4 className='m-0 mb-1'>{this.state.SubscriptionInfo.packageName} Plan</h4>
                                                        
                                                        {this.state.SubscriptionInfo.fromDate !== '-' ? (
                                                            <p>{this.state.SubscriptionInfo.fromDate} - {this.state.SubscriptionInfo.toDate}</p>
                                                        ) : (
                                                            <p>No Expiration</p>
                                                        )}
                                                        
                                                        <Link className='btn btn-primary btn-sm' to="/pricing">Upgrade Package</Link>
                                                    </div>
                                                    <div className='d-flex justify-content-start'>
                                                        <h2>${this.state.SubscriptionInfo.packageCost}</h2>
                                                    </div>
                                                </div>
                                                </>
                                                ) : (
                                                    <div className="card-body m-auto">
                                                        <SpinnerLoader/>
                                                    </div>
                                                )
                                            }
                                        
                                    </div>
                                </div>
                                {/* <div className='col-4'>
                                    <div className="card">
                                        <div className="card-body">
                                            <p className='m-0 mb-1'>Next payment</p>
                                            <h4 className='m-0 mb-3'>On 13 Apr 2022</h4>
                                            <button className='btn btn-outline-primary btn-sm'>Renew Now</button>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            {this.state.SubscriptionInvoiceStatus === 1 && this.state.SubscriptionInvoice.length > 0 &&
                                <div className='row mb-4'>
                                    <div className='col-12'>
                                        <h5>Invoice ({this.state.SubscriptionInvoice.length})</h5>
                                    </div>

                                    {this.state.SubscriptionInvoice.length !== 0 ? (
                                        this.state.SubscriptionInvoice.map(item => 
                                            <div className='col-12' key={item.paymentid}>
                                                <div className='card mb-2'>
                                                    <div className='card-body d-flex justify-content-between p-3 invoice-list'>
                                                        <div className='d-flex justify-content-start'>
                                                            <img src={IconPDFSolidBlack} alt="pdf" />
                                                            <a target="_blank" href={`https://resumion.com/services/DownloadInvoice.php?paymentid=${item.paymentid}`} className='m-0 invoice-number'>#{item.invoicename}</a>
                                                        </div>
                                                        <div>
                                                            <p className='m-0 invoice-date'>
                                                                Date of Invoice: 
                                                                <span className='date'>{item.dateOfInvoice}</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>                                            
                                        )
                                            
                                        ) : (
                                            <div className="card-body m-auto">
                                                <SpinnerLoader/>
                                            </div>
                                        )
                                    }
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})


// export default Subscription;
export default connect(mapStateToProps, { isLoggedIn })(Subscription);
