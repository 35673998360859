import { combineReducers } from 'redux';
import RegFormReducer from './RegFormReducer';
import EducationReducer from './EducationReducer';
import EmploymentReducer from '../reducers/EmploymentReducer';
import SkillReducer from '../reducers/SkillReducer';
import LanguageReducer from '../reducers/LanguageReducer';
import CommonReducer from '../reducers/CommonReducer';
import ViewResumeReducer from '../reducers/ViewResumeReducer';

export default combineReducers({
    REG_FORM_REDU: RegFormReducer,
    EDU_REDU: EducationReducer,
    EMP_REDU: EmploymentReducer,
    SKL_REDU: SkillReducer,
    LAN_REDU: LanguageReducer,
    COM_REDU: CommonReducer,
    VIEW_RES_REDU: ViewResumeReducer
})