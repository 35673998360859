import {
    FETCH_USER_LOGIN,
    CALL_LOADER,
    IS_LOGGEDIN,
    RETRY_LOGIN,
    LOGOUT_USER,
    SEND_REG_USER_DATA,
    GET_IS_SUBSCRIBED,
    VIEW_CONTROLLER,
    GET_JOB_CATEGORY_LIST,
    GET_AVATAR,
    GET_STATUS_SHARE_RESUME,
    GET_PRODUCT_LIST,
    UPDATE_DASHBOARD_TAB
} from './type';
import axios from 'axios';
import qs from 'querystring';

import { getUIDFromSession, getUseridFromSession } from '../components/CommonFunctions';

export const callLoader = () => dispatch => {
    // console.log('callLoader called')
    dispatch({
        type: CALL_LOADER,
        payload: {
            btn_loader: '1',
            is_disabled: 'disabled'
        }
    })
}

// export const removePageLoader = () => dispatch => {
//     console.log('removePageLoader called')
//     dispatch({
//         type: REMOVE_PAGE_LOADER,
//         payload: false
//     })
// }
export const fetchUserLogin = (email, password) => dispatch => {
    console.log('fetchUserLogin called')
    // console.log(credentials)
    localStorage.setItem("user", JSON.stringify(['', '', '', null]))
    localStorage.setItem("uid", JSON.stringify(['']))
    localStorage.setItem("avatar", JSON.stringify(['']))
    // localStorage.setItem("proR", JSON.stringify(['']))
    // localStorage.setItem("proC", JSON.stringify(['']))
    // localStorage.setItem("cksub", JSON.stringify(['']))
    const data = {
        email: email,
        password: password
    }
    const config = {
        "Content-Type": "application/json"
    }
    axios.post('https://resumion.com/apiv2/Login.php', qs.stringify(data), config)
        .then(res => res.data)
        .then(res => {
            dispatch({
                type: FETCH_USER_LOGIN,
                payload: {
                    data: res.data[0],
                    success: res.success
                }
            })
            // dispatch(getAvatar())
            // console.log(res.success)
            // console.log(res.data[0])
            if (res.success === 1) {
                localStorage.setItem("user", JSON.stringify([
                    res.data[0].first_name, 
                    res.data[0].last_name, 
                    res.data[0].userid, 
                    res.success, 
                    res.data[0].title
                ]))
                localStorage.setItem("avatar", JSON.stringify([res.data[0].avatar]))
                // localStorage.setItem("proR", JSON.stringify([res.data[0].resumeid]))
                // localStorage.setItem("proC", JSON.stringify([res.data[0].coverletterid]))
                localStorage.setItem("uid", JSON.stringify([res.data[0].uid]))
                // localStorage.setItem("emlV", JSON.stringify([res.data[0].verified]))
                dispatch(getAvatar([res.data[0].avatar]))

                // axios.get('https://resumion.com/apiv2/CheckSubscription.php?uid='+res.data[0].uid)
                // .then(res => {
                //     localStorage.setItem("cksub", JSON.stringify([res.data.data[0].subscribed]))
                // })
            }
        })
        .catch(error => {
            // console.log(error.res)
        })
}

export const retryLogin = () => dispatch => {
    // console.log('retryLogin called')
    dispatch({
        type: RETRY_LOGIN,
        payload: {
            success: null
        }
    })
}

export const logoutUser = () => dispatch => {
    // console.log('logoutUser called')
    dispatch({ type: LOGOUT_USER })
    localStorage.setItem("user", JSON.stringify(['', '', '', null, '']));
    localStorage.setItem("avatar", JSON.stringify(['']));
    localStorage.setItem("uid", JSON.stringify(['']));
    // localStorage.setItem("proR", JSON.stringify(['']));
    // localStorage.setItem("proC", JSON.stringify(['']));
    // localStorage.setItem("cksub", JSON.stringify(['']));
}

export const isLoggedIn = () => dispatch => {
    console.log('isLoggedIn called')
    if (localStorage.getItem("user") === null) {
        localStorage.setItem("user", JSON.stringify(['', '', '', null, '']))
        localStorage.setItem("avatar", JSON.stringify(['']))
        localStorage.setItem("uid", JSON.stringify(['']));
        // localStorage.setItem("proR", JSON.stringify(['']))
        // localStorage.setItem("proC", JSON.stringify(['']))
        // localStorage.setItem("cksub", JSON.stringify(['']))
        dispatch({
            type: IS_LOGGEDIN,
            payload: JSON.stringify(['', '', '', null])
        })
    }
    if (localStorage.getItem("user") !== null) {
        dispatch({
            type: IS_LOGGEDIN,
            payload: [
                JSON.parse(localStorage.getItem("user")), 
                // JSON.parse(localStorage.getItem("proR")), 
                // JSON.parse(localStorage.getItem("proC"))
            ]
            // payload: JSON.parse(localStorage.getItem("user"))
        })

        dispatch({
            type: GET_AVATAR,
            payload: JSON.parse(localStorage.getItem("avatar")) !== null && JSON.parse(localStorage.getItem("avatar"))[0]
        })
        // console.log(localStorage.getItem("user"))
    }
}

export const getAvatar = (avatar) => dispatch => {
    // console.log('getAvatar called')

    if (localStorage.getItem("avatar") !== '') {
        dispatch({
            type: GET_AVATAR,
            payload: avatar
        })
    }

    // console.log(localStorage.getItem("avatar"))

    if (localStorage.getItem("avatar") !== '') {
        dispatch({
            type: GET_AVATAR,
            payload: JSON.parse(localStorage.getItem("avatar")) !== null && JSON.parse(localStorage.getItem("avatar"))[0]
        })
        // console.log(localStorage.getItem("avatar"))
    }

}

export const sendRegUserData = (regdata) => dispatch => {
    // console.log('sendRegUserData called')
    const data = {
        first_name: regdata.first_name,
        last_name: regdata.last_name,
        email: regdata.email,
        phone: regdata.phone,
        address: regdata.address,
        country: regdata.country,
        city: regdata.city,
        job_category: regdata.job_category,
        templateid: regdata.templateid,
        password: regdata.password,
        terms: regdata.terms,
        newsletters: regdata.newsletters,
    }
    const config = {
        "Content-Type": "application/json"
    }
    // console.log(data)
    axios.post('https://resumion.com/apiv2/CreateAccount.php', qs.stringify(data), config)
        .then(res => res.data)
        .then(res => {
            // console.log(res)
            dispatch({
                type: SEND_REG_USER_DATA,
                payload: res
            })
            localStorage.setItem("user", JSON.stringify([res.data[0].first_name, res.data[0].last_name, res.data[0].userid, res.success, res.data[0].title, "0"]))
            localStorage.setItem("avatar", JSON.stringify(['']))
            localStorage.setItem("uid", JSON.stringify([res.data[0].uid]))
            // localStorage.setItem("proR", JSON.stringify([res.data[0].resumeid]))
            // localStorage.setItem("proC", JSON.stringify([res.data[0].coverletterid]))
            // Calling action creator inside another action creator
            dispatch(isLoggedIn())
        })
        .catch(error => {
            // console.log(error.response)
        })

}

export const viewController = (data) => dispatch => {
    // console.log('callLoader called')
    dispatch({
        type: VIEW_CONTROLLER,
        payload: data
    })
}

export const getJobCategoryList = () => dispatch => {
    axios.get('https://resumion.com/apiv2/GetJobCategories.php')
        .then(res => res.data.data[0].category)
        .then(res =>
            dispatch({
                type: GET_JOB_CATEGORY_LIST,
                payload: res
            })
        )
}

export const getStatusShareResume = () => dispatch => {
    axios.get('https://resumion.com/apiv2/GetUserPublicLink.php?userid=yssWfp4m8W&templateid=1')
        .then(res => res.data)
        .then(res =>
            dispatch({
                type: GET_STATUS_SHARE_RESUME,
                payload: res
            })
        )
}

export const getProductList = () => dispatch => {
    console.log('getProductList called', getUIDFromSession())
    localStorage.getItem("uid") !== '[""]' &&
    axios.get(`https://resumion.com/apiv2/Getallresumeinfo.php?uid=${getUIDFromSession()}&userid=${getUseridFromSession()}`)
    .then(res => {
        dispatch({
            type: GET_PRODUCT_LIST,
            payload: [res.data.data[0].resumeinfo, res.data.data[0].coverinfo]
        })
    })
}

export const getIsSubscribed = () => dispatch => {
    console.log('isSubscribed called', getUIDFromSession())
    localStorage.getItem("uid") !== '[""]' &&
    axios.get(`https://resumion.com/apiv2/CheckSubscription.php?uid=${getUIDFromSession()}&userid=${getUseridFromSession()}`)
    .then(res => {
        dispatch({
            type: GET_IS_SUBSCRIBED,
            payload: [res.data.data[0].subscribed, res.data.data[0].validity]
        })
    //    console.log([res.data.data[0].subscribed, res.data.data[0].validity])
    //    localStorage.setItem("cksub", JSON.stringify([res.data.data[0].subscribed]))
    })
    .catch(error => {
        // console.log(error.res)
    })
}

export const updateDashboardTab = (tab) => dispatch => {
    dispatch({
        type: UPDATE_DASHBOARD_TAB,
        payload: tab
    })
}