import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession, getURLSearchParams } from '../CommonFunctions';
import MetaTags from 'react-meta-tags';
import ThumbnailLoader from '../../ThumbnaiListlLoader';
import { Link, Redirect } from 'react-router-dom';
import BtnLoader from '../../BtnLoader';
import axios from 'axios';
import qs from 'querystring';
import history from '../../history';
import {
    IconAngleLeftWhite
} from '../../icon/index';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class ChangeRT extends Component {
    state = {
        resume_template_id: '',
        resume_template_list: [],
        coverletter_template_id: '',
        coverletter_template_list: [],
        userid: '',
        btn_loader: false,
        isDisabled: 'disabled',
        update_type: '',
        id: '',
        success: ''
    }
    componentDidMount() {
        removeLoaders();
        // this.nameInput.focus();
        // console.log(this.props.location.hash.slice(1,2), this.props.location.hash.slice(3))
        this.setState({ userid: getUseridFromSession() })
        
        // const update_type = this.props.location.hash.slice(1,2);
        // const id = this.props.location.hash.slice(3);

        let query = getURLSearchParams(this.props.location.search)
        const update_type = query.get('type')
        const id = query.get('id')
        console.log(update_type, id)


        this.setState({ update_type, id })

        update_type === 'resume' && (
            axios.get('https://resumion.com/apiv2/ResumeTemplates.json')
                .then(res => this.setState({
                    resume_template_list: res.data.data
                })
            )
        )
        update_type === 'coverletter' && (
            axios.get('https://resumion.com/apiv2/CoverletterTemplates.json')
            .then(res => this.setState({
                coverletter_template_list: res.data.data
            }))
        )
        // this.setState({
        //     update_type: this.props.match.params.type,
        //     id: this.props.match.params.id
        // })
    }

    checkMandatoryFieldsForResume = () => {
        const resume_template_id = this.state.resume_template_id;

        resume_template_id !== '' ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )   
    }

    checkMandatoryFieldsForCoverletter = () => {
        const coverletter_template_id = this.state.coverletter_template_id;

        coverletter_template_id !== '' ? (
            this.setState({
                ...this.state,
                isDisabled: ''
            })
        ) : (
            this.setState({
                ...this.state,
                isDisabled: 'disabled'
            })
        )   
    }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const dataResume = {
            resumeid: this.state.id,
            templateid: this.state.resume_template_id,
            userid: getUseridFromSession()
        }

        const dataCoverletter = {
            coverid: this.state.id,
            templateid: this.state.coverletter_template_id,
            userid: getUseridFromSession()
        }

        const config = {
            "Content-Type": "application/json"
        }
        axios.post(
        this.state.update_type === 'resume' ? 'https://resumion.com/apiv2/SetResumeTemplate.php' : this.state.update_type === 'coverletter' ? 'https://resumion.com/apiv2/SetCoverTemplate.php' : null, 
            qs.stringify(this.state.update_type === 'resume' ? dataResume : this.state.update_type === 'coverletter' ? dataCoverletter : null), config)

        // .then(res => {
        //      this.setState({
        //         success: res.data.success
        //     })
        // })
        .then (
            // e.preventDefault();
            history.goBack()
        )
    }

    handleTemplateSelection = (type, template_id) => {
        type === 'resume' && (
            this.setState({ resume_template_id: template_id }, () => this.checkMandatoryFieldsForResume())
        )
        type === 'coverletter' && (
            this.setState({ coverletter_template_id: template_id }, () => this.checkMandatoryFieldsForCoverletter())
        )
    }
    

    render() {
        if (this.state.success === 1) {
            return (
                <Redirect to='/dashboard' />
            )
        }

        if (this.state.resume_template_list.length !== 0) {
            var resume_templates = this.state.resume_template_list.map(template => {
                return (
                    <div className='col-md-4' key={template.id}>
                        <button type='button' className={`template text-center p-0 border-0 bg-transparent w-100 ${template.id === this.state.resume_template_id && 'active'}`} onClick={()=>this.handleTemplateSelection('resume', template.id)}>
                            <img src={template.thumbnail} className="img-fluid mb-2 thumbnail" alt={template.thumbnail} />
                            <h5 className='pb-0 title text-black-1'>{template.title}</h5>
                        </button>
                    </div>
                )
            })
        }

        if (this.state.coverletter_template_list.length !== 0) {
            var coverletter_templates = this.state.coverletter_template_list.map(template => {
                return (
                    <div className='col-md-4' key={template.id}>
                        <button type='button' className={`template text-center p-0 border-0 bg-transparent w-100 ${template.id === this.state.coverletter_template_id && 'active'}`} onClick={()=>this.handleTemplateSelection('coverletter', template.id)}>
                            <img src={template.thumbnail} className="img-fluid mb-2 thumbnail" alt={template.thumbnail} />
                            <h5 className='pb-0 title text-black-1'>{template.title}</h5>
                        </button>
                    </div>
                )
            })
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Create Resume - Resumion</title>
                        <meta property="og:title" content="Create Resume - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>

                <header className="preview-panel position-absolute w-100">
                    <div className="d-flex action-bar align-items-center justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                        <Link to="/dashboard" className="btn text-white btn-back d-flex align-items-center"><img className="mr-3" src={IconAngleLeftWhite} alt="back" height="18"/>Back</Link>
                        <div className="action-title text-center position-absolute w-100">
                            <h5 className='text-white'>Change {this.state.update_type === 'resume' && ('resume')}{this.state.update_type === 'coverletter' && ('cover letter')} template</h5>
                            {/* <p>{this.state.update_type}, {this.state.id}</p> */}
                        </div>
                        <button className="btn btn-primary m-1" disabled={this.state.isDisabled} onClick={ this.handleSubmit }>
                            {this.state.btn_loader ? (<BtnLoader />) : <span>Apply</span>}
                        </button>
                    </div>
                </header>

                <div className="d-flex justify-content-center">
                    <div className="preview-container rc rounded-lg">
                        <div className="card createrc bg-transparent border-0">
                            <div className="card-body pt-3 pb-5 p-0">
                            {this.state.update_type === 'resume' &&
                            <>
                            {this.state.resume_template_list.length !== 0 ? (
                                <form>
                                    <div className="form-group">
                                        <div className='row template-list'>
                                            {resume_templates}
                                        </div>
                                    </div>
                                    
                                </form>)
                                : (
                                    <ThumbnailLoader />
                                )}
                                </>
                                }
                                {this.state.update_type === 'coverletter' &&
                                    <>
                                    {this.state.coverletter_template_list.length !== 0 ? (
                                        <form>
                                            <div className="form-group">
                                                <div className='row template-list'>
                                                    {coverletter_templates}
                                                </div>
                                            </div>
                                            
                                        </form>)
                                    : (
                                        <ThumbnailLoader />
                                    )}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default ChangeRT;