import React, { Component } from 'react';
import { removeLoaders, setFileDownload, getURLSearchParams } from '.././CommonFunctions';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SpinnerLoader from '../../../src/Loader/SpinnerLoader';
import Footer from '../Footer';
import { connect } from 'react-redux';
import { isLoggedIn } from '../../actions/CommonActions';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class FreeFiles extends Component {
    state = {
        files: [],
        types: [],
    }
    componentDidMount() {
        removeLoaders();
        this.props.isLoggedIn();

        let query = getURLSearchParams(this.props.location.search)
        const fileid = query.get('id')
        axios.get('/apiv2/GetTemplateFiles.php?id='+fileid)
        .then(res =>
            res.data.success === 1 ? (
                this.setState({
                    files: res.data.data[0].files,
                    types: res.data.data[0].files[0].type.split(',')
                }),
                setFileDownload(fileid)
            ) : (
                window.location.href = '/'
            )
        )
    }

    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(this.state)

        // console.log(this.props.user_credentials.success)

        const success_redux = this.props.user_credentials.success

        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Download resume template - Resumion</title>
                        <meta property="og:title" content="Download resume template - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                
                    <div className="d-flex justify-content-center">
                        <div className="col-sm-6 col-lg-12">
                            <div className="card bg-transparent border-0">
                                <div className="card-body text-center p-5">
                                    {this.state.files.length !== 0 ? (
                                    <>
                                        <img className='img-fluid mb-4' src={`https://resumion.com/images/free-files/${this.state.files[0].thumb_small}`} alt={this.state.files[0].title}/>
                                        <h4 className='mb-1'>{this.state.files[0].title}</h4>
                                        <p className='d-inline'>Available Format: </p>{this.state.types.map(i => 
                                            i === "1" ? <span class="badge badge-primary mx-1">PSD</span> :
                                            i === "2" ? <span class="badge badge-danger mx-1">AI</span> :
                                            i === "3" ? <span class="badge badge-primary mx-1">Doc</span> :
                                            i === "4" ? <span class="badge badge-primary mx-1">Docx</span> :
                                            i === "5" ? <span class="badge badge-danger mx-1">Figma</span> :
                                            i === "6" ? <span class="badge badge-danger mx-1">XD</span> :
                                            i === "7" ? <span class="badge badge-danger mx-1">InDesign</span> : null
                                        )}
                                        <p className="text-secondary mb-4 mt-2">
                                        This template is design and distributed by {this.state.files[0].author}
                                        </p>

                                        {/* {console.log(success_redux, session_success)} */}

                                        {success_redux !== 1 && session_success !== 1 ? (
                                            <Link className='btn btn-primary btn-sm ml-3' to='/builder'>Create Account to Download</Link>
                                            ) : (
                                                
                                                <a target="_blank" rel="noopener noreferrer" className='btn btn-primary btn-sm' href={this.state.files[0].url}>Download Now</a>
                                            )
                                        }
                                    </>
                                    ) : (
                                        <SpinnerLoader/>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer/>
            </React.Fragment>
        );
    }
}
const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})
export default connect(mapStateToProps, { isLoggedIn })(FreeFiles);