import { FETCH_EDUCATIONS, FETCH_EDUCATIONS_CONTROLLER, EDUCATION_ADD_NEW, FETCH_EDUCATION_ADD_BTN, EDUCATION_SAVE} from '../actions/type';

const initialState = {
    education_add_new: {
        degree: '',
        school: '',
        city: '',
        startmonth: '',
        startyear: '',
        endmonth: '',
        endyear: ''
    },
    educations: '',

    educations_controller: {
        educationEmptyAdd: '0',
        educationView: '1',
        educationEdit: '0',
        addButtonVisibility: '1'
    }
    
};

export default function(state = initialState, actions){
    switch(actions.type){
        case FETCH_EDUCATIONS:
            return {
                ...state,
                educations: actions.payload
            };
        case FETCH_EDUCATIONS_CONTROLLER:
            return {
                ...state,
                educations_controller: actions.payload
            };
        case EDUCATION_ADD_NEW:
            return {
                ...state,
                // education_add_new: actions.payload
                education_add_new: {
                    ...state.education_add_new,
                    ...actions.payload
                }
            };
        case FETCH_EDUCATION_ADD_BTN:
            return {
                ...state,
                educations_controller: {
                    ...state.educations_controller,
                    ...actions.payload
                }
            };
        case EDUCATION_SAVE:
            return {
                ...state,
                // education_add_new: actions.payload
                educations_controller: {
                    ...state.educations_controller,
                    ...actions.payload
                }
            }
        default:
            return state;
    }
}