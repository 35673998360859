import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchViewResume, updateViewResumeID } from '../actions/ViewResumeActions';
import { isLoggedIn, getIsSubscribed } from '../actions/CommonActions';
import { removeLoaders, getURLSearchParams, getUseridFromSession, checkResumeTemplateAccess } from './CommonFunctions';
import Objective from './ViewResume/Objective';
import PersonalDetails from './ViewResume/PersonalDetails';
import Skills from './ViewResume/Skills';
import Languages from './ViewResume/Languages';
import Education from './ViewResume/Education';
import Employments from './ViewResume/Employments';
import Courses from './ViewResume/Courses';
import References from './ViewResume/References';
import Interests from './ViewResume/Interests';
import CustomArea from './ViewResume/CustomArea';
import Footer from './Footer';
import { IconFileAlt, IconShareDark, IconDownloadDark, IconExchangeSolid } from '../icon/index';
import { Link, Redirect } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import axios from 'axios';
import qs from 'querystring';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class Resume extends Component {
    state = {
        details_view: '',
        basicinfo: null,
        objective: null,
        education: null,
        employments: null,
        courses: null,
        skills: null,
        languages: null,
        references: null,
        interests: null,
        customareas: null,
        job_category_list: null,
        userid: '',
        resumeid: '',
        templateid: '',
        premium: '',
    }
    

    async componentDidMount() {
        let query = getURLSearchParams(this.props.location.search)
        const resumeid = query.get('id')
        // console.log(resumeid)
        this.setState({ 
            resumeid,
            userid: getUseridFromSession()
        })
        // this.props.fetchViewResume(resumeid);

        axios.get(`https://resumion.com/apiv2/ViewResume.php?resumeid=${resumeid}&userid=${getUseridFromSession()}`)
        .then(res => res.data)
        .then(res => {
            if(res.success === 1){
                // console.log(res.data[0])
                this.setState({
                    basicinfo: res.data[0].basicinfo[0],
                    objective: res.data[0].objective[0],
                    education: res.data[0].education,
                    employments: res.data[0].employments,
                    courses: res.data[0].courses,
                    skills: res.data[0].skills,
                    languages: res.data[0].languages,
                    references: res.data[0].references,
                    interests: res.data[0].interests,
                    customareas: res.data[0].customareas,
                })
            }
        })

        try {
            const response = await axios.get(`https://resumion.com/apiv2/GetResumeCoverInfo.php?resumeid=${resumeid}&userid=${getUseridFromSession()}&type=resume`);
            this.setState({
                templateid : response.data.data[0].info[0].templateid,
                premium: response.data.data[0].info[0].premium
            })
            console.log(response.data.data[0].info[0]);
        } catch (error) {
            console.error(error);
        }
        this.props.getIsSubscribed();
        removeLoaders();
    }


    render() {
        // console.log(this.props.details_view.customarea)
        // console.log(this.state.details_view)

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3];
            // console.log(session_success)
        }

        // console.log(this.props.details_view.objective)
        // console.log(getUseridFromSession())
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }


        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>My Resume - Resumion</title>
                        <meta property="og:title" content="My Resume - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className='container'>
                    {/* <div className="d-flex justify-content-center"> */}
                    <div className="row">
                        <div className="col-sm-8 col-lg-9">
                            <PersonalDetails basicinfo={this.state.basicinfo} />
                            <Objective objective={this.state.objective} />
                            <Education education={this.state.education} resumeid={this.state.resumeid}/>
                            <Employments employments={this.state.employments} resumeid={this.state.resumeid}/>
                            <Courses courses={this.state.courses} resumeid={this.state.resumeid}/>
                            <Skills skills={this.state.skills} resumeid={this.state.resumeid} />
                            <Languages languages={this.state.languages} resumeid={this.state.resumeid}/>
                            <References references={this.state.references} resumeid={this.state.resumeid}/>
                            <Interests interests={this.state.interests} resumeid={this.state.resumeid}/>
                            <CustomArea customareas={this.state.customareas} resumeid={this.state.resumeid}/>
                        </div>
                        <div className="col-sm-4 col-lg-3">
                            <div className='card mb-md-4 mt-md-4 action-btn-group-bottom'>
                                <div className='card-body'>
                                    <Link type="button" to={`/preview-resume?id=${this.state.resumeid}`} className="btn btn-light btn-sm btn-block"><img src={IconFileAlt} height="20" alt='preview' /> Preview</Link>
                                    <Link type="button" to={`/change-rc?type=resume&id=${this.state.resumeid}`} className="btn btn-light btn-sm btn-block"><img src={IconExchangeSolid} height="20" alt='template change'/> Template Change</Link>
                                    <Link type="button" to={`/share-resume?id=${this.state.resumeid}`} className="btn btn-light btn-sm btn-block d-none d-md-block"><img src={IconShareDark} height="20" alt='share' /> Share</Link>
                                    {this.props.is_subscribed === 1 && this.state.premium === "1" || this.state.premium === "0" ?
                                        <a rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadResume.php?resumeid=${this.state.resumeid}&userid=${getUseridFromSession()}&actiontype=download`} className="btn btn-light btn-sm btn-block"><img src={IconDownloadDark} height="20" alt='download' /> Download</a>
                                        : (
                                        <Link className="btn btn-light btn-sm btn-block" to={`premium`}><img src={IconDownloadDark} height="24" alt='download'/> Download</Link>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    details_view: state.VIEW_RES_REDU.details_view ? state.VIEW_RES_REDU.details_view : '',
    resumeid_details_view: state.VIEW_RES_REDU.resumeid_details_view,
    is_subscribed: state.COM_REDU.is_subscribed,
})

export default connect(mapStateToProps, { isLoggedIn, fetchViewResume, updateViewResumeID, getIsSubscribed })(Resume);