import { FETCH_REGFORMDATA, FETCH_CHECKEMAIL } from './type';
import axios from 'axios';
import qs from 'querystring';

export const fetchRegFormData = (regformdata) => dispatch => {
    // console.log('fetchRegFormData called')
    // console.log(regformdata)
    dispatch({
        type: FETCH_REGFORMDATA,
        payload: regformdata
    })
}

export const fetchCheckEmail = (email) => dispatch => {
    // console.log('fetchCheckEmail called')
    // console.log(email)
    const data = {
        email: email
    }
    const config = {
        "Content-Type": "application/json"
    }
    axios.post('https://resumion.com/apiv2/CheckEmail.php', qs.stringify(data), config)
        .then(res => res.data.success)
        .then(res => {
            dispatch({
                type: FETCH_CHECKEMAIL,
                payload: res
            })
        })
}


