import React, { Component } from 'react';
import ContentLoader from 'react-content-loader'

const MyLoader = () => (
    <ContentLoader
        width={700}
        height={575}
        viewBox="0 0 700 575"
    >
        <rect x="12" y="9" rx="2" ry="2" width="140" height="10" />
        <rect x="12" y="30" rx="2" ry="2" width="667" height="11" />
        <rect x="12" y="58" rx="2" ry="2" width="211" height="211" />
        <rect x="240" y="57" rx="2" ry="2" width="211" height="211" />
        <rect x="467" y="56" rx="2" ry="2" width="211" height="211" />
        <rect x="12" y="283" rx="2" ry="2" width="211" height="211" />
        <rect x="240" y="281" rx="2" ry="2" width="211" height="211" />
        <rect x="468" y="279" rx="2" ry="2" width="211" height="211" />
    </ContentLoader>
  )

class ThumbnaiListlLoader extends Component {
    render() { 
        return (
            <MyLoader />
        );
    }
}


export default ThumbnaiListlLoader;