import React, { Component } from 'react';
import { IconFlagChecked } from '../icon/index';
import { Link } from 'react-router-dom';

class RegSuccess extends Component {
    state = {}
    render() {
        return (
            <div className="d-flex justify-content-center text-center">
                <div className="bg-white p-5 mt-5 shadow-sm rounded-lg">
                    <img src={IconFlagChecked} alt="Success" height="84" />
                    <h5 className="mt-4">Congratulations</h5>
                    <p>Welcome to the world of Resumion. <br/>Please check your email to verify your account.</p>
                    <Link to='/my-resume' className="btn btn-success">Continue</Link>
                </div>
            </div>
        );
    }
}

export default RegSuccess;