import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from '.././CommonFunctions';
import MetaTags from 'react-meta-tags';
import ImgPurchase from '../../img/purchase.svg';
import { NavLink, Link } from 'react-router-dom';

class PaymentStatus extends Component {
    componentDidMount() {
        removeLoaders();
    }

    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(this.state)

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Payment Status - Resumion</title>
                        <meta property="og:title" content="Payment Status - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="col-sm-6 col-lg-12">
                        <div className="card bg-transparent border-0">
                            <div className="card-body text-center p-5">
                                <img className='img-fluid mb-4' src={ImgPurchase} alt="purchase success"/>
                                <h4>Congratulations</h4>
                                <p className="text-secondary mb-4">
                                    You have successfully purchased the Subscription
                                </p>
                                <Link className='btn btn-primary btn-sm' to='/dashboard'>Continue to Builder</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
 
export default PaymentStatus;