import React, { Component } from 'react';
import { IconInboxCircle  } from '../icon/index';

class EmptyState extends Component {
    state = {  }
    render() { 
        return (
            <div className="col text-center">
                <img className="mb-3" src={IconInboxCircle} alt="empty"/>
                <h6>No data available</h6>
                <span className="d-block text-muted mb-3">Please add some information</span>
            </div>
        );
    }
}
 
export default EmptyState;