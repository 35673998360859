// USER LOGIN
export const FETCH_USER_LOGIN = 'FETCH_USER_LOGIN';

// REMOVE PAGE LOADER
export const REMOVE_PAGE_LOADER = 'REMOVE_PAGE_LOADER';
export const CALL_LOADER = 'CALL_LOADER';
export const GET_AVATAR = 'GET_AVATAR';
export const IS_LOGGEDIN = 'IS_LOGGEDIN';
export const RETRY_LOGIN = 'RETRY_LOGIN';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const LOGOUT_USER = 'LOGOUT_USER';
export const SEND_REG_USER_DATA = 'SEND_REG_USER_DATA';
export const FETCH_REGFORMONE = 'FETCH_REGFORMONE';
export const FETCH_REGFORMDATA = 'FETCH_REGFORMDATA';
export const FETCH_CHECKEMAIL = 'FETCH_CHECKEMAIL';
export const FETCH_PERSONALDETAILS = 'FETCH_PERSONALDETAILS';
export const FETCH_EDUCATIONS = 'FETCH_EDUCATIONS';
export const FETCH_EDUCATIONS_CONTROLLER = 'FETCH_EDUCATIONS_CONTROLLER';
export const EDUCATION_ADD_NEW = 'EDUCATION_ADD_NEW';
export const FETCH_EDUCATION_ADD_BTN = 'FETCH_EDUCATION_ADD_BTN';
export const EDUCATION_SAVE = 'EDUCATION_SAVE';

// START EMPLOYMENT
export const FETCH_EMPLOYMENTS = 'FETCH_EMPLOYMENTS';
export const EMPLOYMENT_ADD = 'EMPLOYMENT_ADD';
export const INSERT_EMPLOYMENT_FORM = 'INSERT_EMPLOYMENT_FORM';
export const REMOVE_EMPLOYMENT_FORM = 'REMOVE_EMPLOYMENT_FORM';

// START PROFESSIONAL SKILLS
export const FETCH_SKILLS = 'FETCH_SKILLS';

// START LANGUAGES
export const FETCH_LANGUAGES = 'FETCH_LANGUAGES';

// VIEW CONTROLLER
export const VIEW_CONTROLLER = 'VIEW_CONTROLLER';

// VIEW RESUME DETAILS
export const FETCH_VIEW_RESUME = 'FETCH_VIEW_RESUME';
export const UPDATE_VIEW_RESUME_ID = 'UPDATE_VIEW_RESUME_ID';

// VIEW COVER LETTER DETAILS
export const FETCH_VIEW_CL = 'FETCH_VIEW_CL';

// GET JOB CATEGORY LIST
export const GET_JOB_CATEGORY_LIST = 'GET_JOB_CATEGORY_LIST';

// GET STATUS SHARE RESUME
export const GET_STATUS_SHARE_RESUME = 'GET_STATUS_SHARE_RESUME';

// GET RESUME AND COVERLETTER LIST
export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';

// GET GET_IS_SUBSCRIBED
export const GET_IS_SUBSCRIBED = 'GET_IS_SUBSCRIBED';

// UPDATE DASHBOARD TAB
export const UPDATE_DASHBOARD_TAB = 'UPDATE_DASHBOARD_TAB';