import { FETCH_EMPLOYMENTS, EMPLOYMENT_ADD, INSERT_EMPLOYMENT_FORM, REMOVE_EMPLOYMENT_FORM } from '../actions/type';

const initialState = {
    employments: '',

    employments_controller: {
        employmentEmptyAdd: '0',
        employmentView: '1',
        employmentEdit: '0',
        addButtonVisibility: '1',
        SpinnerOval: '0'
    }
};

export default function(state = initialState, actions){
    switch(actions.type){
        case FETCH_EMPLOYMENTS:
            return {
                ...state,
                // employments: [...state.employments, actions.payload]
                employments: actions.payload
            };
        case EMPLOYMENT_ADD:
            return {
                ...state,
                // Get response from API and update exsiting data
                employments: [...state.employments, actions.payload],
                employments_controller: {
                    ...state.employments_controller,
                    SpinnerOval: '0'
                }
            };
        case INSERT_EMPLOYMENT_FORM:
            return {
                ...state,
                employments_controller: {
                    ...state.employments_controller,
                    ...actions.payload
                }
            };
        case REMOVE_EMPLOYMENT_FORM:
            return {
                ...state,
                employments_controller: {
                    ...state.employments_controller,
                    ...actions.payload
                }
            }
        default:
            return state;
    }
}

