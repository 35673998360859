import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from '.././CommonFunctions';
import MetaTags from 'react-meta-tags';
import ImgVault from '../../img/vault.svg';
import { NavLink, Link } from 'react-router-dom';
import Footer from '../Footer';

class PremiumFeatures extends Component {
    componentDidMount() {
        removeLoaders();
    }

    render() { 
        // this.handleChange = this.handleChange.bind(this);
        // console.log(this.state)

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Contact Us - Resumion</title>
                        <meta property="og:title" content="Contact Us - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="d-flex justify-content-center">
                    <div className="col-sm-6 col-lg-6">
                        <div className="card bg-transparent border-0">
                            <div className="card-body text-center p-5">
                                <img className='img-fluid mb-4' src={ImgVault} alt="vault"/>
                                <h4>Sorry, but you have exceeded the resume limit for the free plan.</h4>
                                <p className="text-secondary mb-4">
                                    You can only create one resume with selected free template in this plan. To create an unlimited number of resumes, you need to upgrade your plan.
                                </p>
                                <Link className='btn btn-primary btn-sm' to='/pricing'>Upgrade Now</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </React.Fragment>
        );
    }
}
 
export default PremiumFeatures;