import React, { Component } from 'react';
import { removeLoaders, getUseridFromSession } from './CommonFunctions';
import BtnLoader from '../BtnLoader';
import Footer from './Footer';
import axios from 'axios';
import qs from 'querystring';
import MetaTags from 'react-meta-tags';

class ContactUs extends Component {
    state = { 
        full_name: '',
        email: '',
        subject: '',
        message: '',
        success: false,
        isDisabled: 'disabled',
        userid: ''
    }
    componentDidMount() {
        removeLoaders();
        this.setState({
            ...this.state,
            userid: getUseridFromSession()
        })
    }
    checkMandatoryFields = () => {
        const full_nameLength = this.state.full_name.length;
        const emailLength = this.state.email.length;
        const subjectLength = this.state.subject.length;
        const messageLength = this.state.message.length;
        {
            full_nameLength !== 0 && emailLength !== 0 && subjectLength !== 0 && messageLength !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        }
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            full_name: this.state.full_name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message,
            userid: this.state.userid
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/ContactUs.php', qs.stringify(data), config)
            // .then(res => console.log(res))
            .then(() => this.endBtnLoader())
            .then(() => this.setState({
                full_name: '',
                email: '',
                subject: '',
                message: '',
                success: true
            }))
            .then(() => this.checkMandatoryFields())
    }
    
    render() { 
        this.handleChange = this.handleChange.bind(this);
        // console.log(this.state)

        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Contact Us - Resumion</title>
                        <meta property="og:title" content="Contact Us - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="page-title text-center mb-4 mt-5">
                    <h3 className="text-center">Contact Resumion</h3>
                    <p className="text-secondary">
                        We are here to help you and answer any question you might have. <br/>We looking forward to hearing from you.
                    </p>
                </div>

                <div className="d-flex justify-content-center mb-5">
                    <div className="col-sm-8 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-column">
                                    {this.state.success &&
                                        <div className="alert alert-success animated fadeIn" role="alert">
                                            Thank you. Your email has been sent :). We will try to reach you shortly.
                                        </div>
                                    }
                                    <form className="personal-details-edit" onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-12 mb-3">
                                                <label htmlFor="full_name">Full name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="full_name" placeholder="e.g. John Doe" value={this.state.full_name} />
                                            </div>
                                            <div className="form-group col-12 mb-3">
                                                <label htmlFor="email">Email address <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="email" className="form-control" name="email" placeholder="name@email.com" value={this.state.email} />
                                            </div>
                                            <div className="form-group col-12 mb-3">
                                                <label htmlFor="subject">Subject <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <select className="form-control" name="subject" onChange={this.handleChange} value={this.state.subject}>
                                                    <option value="">Select</option>
                                                    <option value="general">General</option>
                                                    <option value="others">Others</option>
                                                </select>
                                            </div>
                                            <div className="form-group col-12">
                                                <label htmlFor="message">Message <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <textarea row="8" onChange={this.handleChange} type="text" className="form-control" name="message" placeholder="e.g. I want to build my next resume..." value={this.state.message}></textarea>
                                            </div>
                                            <div className="form-group col-12">
                                                <button className="btn btn-primary" disabled={this.state.isDisabled}>
                                                    {this.state.btn_loader ? (<BtnLoader />) : (<span>Send Now</span>)}
                                                </button>
                                            </div>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default ContactUs;