import React, { Component } from 'react';
import { 
    IconPencilWhite,
    IconShareAltWhite,
    IconDownloadWhite,
    IconAngleLeftWhite,
    IconTimesWhite
} from '../../icon/index';
import ResumeShareModal from '.././ResumeShareModal';
import { removeLoaders, getURLSearchParams, getUseridFromSession } from '../CommonFunctions';
import { NavLink, Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import '../../../src/css/resume_preview.css';
import history from '../../history';
import { connect } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

pdfjs.GlobalWorkerOptions.workerSrc = `https://resumion.com/js/pdf.worker.js`;



class PreviewResume extends Component {
    state = {
        preview_resume: false,
        print_preview: true,
        resume_data: this.props.resume_data,
        resumeid: '',
        pageNumber: 1,
        numPages: null,
    }

    componentDidMount() {
        removeLoaders();
        let query = getURLSearchParams(this.props.location.search)
        const resumeid = query.get('id');
        this.setState({
            resumeid
        })
        console.log(resumeid);

    }

    handleBack = (e) => {
        e.preventDefault();
        history.goBack();
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({
            pageNumber: numPages
        })
    }

    render() {
        // let preview_type = this.state.resumeid;
        this.handleBack = this.handleBack.bind(this);
        let URL = `https://resumion.com/apiv2/DownloadResume.php?resumeid=${this.state.resumeid}&userid=${getUseridFromSession()}&actiontype=preview`;
        // let URL = {`https://resumion.com/apiv2/DownloadResume.php?resumeid=${this.state.resumeid}&templateid=${this.props.match.params.templateid}&actiontype=preview`};
        // console.log(URL);
        return (
            <React.Fragment>
                <header className="preview-panel bg-grey resume-preview position-absolute w-100">
                    <div className="d-flex action-bar justify-content-between align-items-center bg-dark p-2 position-fixed w-100">
                    <button onClick={this.handleBack} className="btn text-white btn-back d-flex align-items-center"><img className="mr-3" src={IconAngleLeftWhite} alt="back" height="18"/>Back</button>
                    {/* {preview_type === 'print' &&
                        <h6 className="text-center text-white">Print Preview</h6>
                    } */}
                    <div className="action-btn-group">
                        <React.Fragment>
                            <ReactTooltip/>
                            <NavLink className="btn btn-primary p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Edit" data-type="light" to={`/resume?id=${this.state.resumeid}`}>
                                <img src={IconPencilWhite} alt="edit" height="24"/>
                            </NavLink>
                            <Link className="btn btn-danger p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Share" data-type="light" to={`/share-resume?id=${this.state.resumeid}`}>
                                <img src={IconShareAltWhite} alt="share" height="24"/>
                            </Link>
                            
                            <a rel="noopener noreferrer" target="_blank" href={`https://resumion.com/apiv2/DownloadResume.php?resumeid=${this.state.resumeid}&userid=${getUseridFromSession()}&actiontype=download`} className="btn btn-success p-0 rounded-circle m-1" data-place="bottom" data-effect="solid" data-tip="Download" data-type="light">
                                <img src={IconDownloadWhite} alt="download" height="24"/>
                            </a>
                        </React.Fragment>
                        </div>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center">
                            <div className="preview-container mb-5 rounded-lg">
                                {this.state.resumeid !== '' &&
                                    <Document 
                                        loading="Loading resume..."
                                        renderMode="svg"
                                        file={URL}
                                        onLoadSuccess={this.onDocumentLoadSuccess}>
                                        {Array.from(
                                            new Array(this.state.pageNumber),
                                            (el, index) => (
                                            <Page
                                                className="d-flex justify-content-center mb-4"
                                                scale={1.3}
                                                key={`page_${index + 1}`}
                                                pageNumber={index + 1}
                                                renderTextLayer={false}
                                            />
                                            ),
                                        )}
                                    </Document>
                                }
                            </div>
                        </div>
                    </div>
                </header>

                {/* <div className="modal fade in" id="shareModal" tabIndex="-1" role="dialog" aria-labelledby="shareModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content position-relative">
                            <button type="button" className="close position-absolute" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">
                                    <img src={IconTimesWhite} alt="times" height="12"/>
                                </span>
                            </button>
                            <div className="modal-body p-4">
                                <ResumeShareModal/>
                            </div>
                        </div>
                    </div>
                </div> */}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(PreviewResume);