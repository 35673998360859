import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isNull } from '../CommonFunctions';
import LayoutLoader from '../../LayoutLoader';
import axios from 'axios';
import qs from 'querystring';
import { toast } from 'react-toastify';
import { IconRocket } from '../../icon/index';
import EmptyState from '../../components/EmptyState';
import BtnLoader from '../../BtnLoader';
import TextareaAutosize from 'react-textarea-autosize';
// import { updateViewResumeID } from '../../actions/ViewResumeActions';
import { 
    IconEdit
 } from '../../icon/index';

class Objective extends Component {
    state = {
        edit: false,
        view: true,
        id: null,
        objective_id: null,
        resumeid: null,
        objective: null,
        objective_edit: null,
        add_new: false,
        layout_loader: true,
        isDisabled: 'disabled',
        btn_loader: false
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }

    componentDidMount() {
      
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.layout_loader === true) {
            this.setState({
                ...this.state,
                layout_loader: false
            })
        }
        
        // console.log(prevState.objective === null, this.props.objective !== null)

        if (prevState.objective === null && this.props.objective !== null) {
            // console.log(1111)
            this.props.objective.objective === null ? (
                this.setState({
                    ...this.state,
                    objective: ''
                })
            ) : (
                this.setState({
                    ...this.state,
                    objective: this.props.objective.objective
                })
            )
            this.state.objective_id === null &&
            this.setState({
                ...this.state,
                objective_id: this.props.objective.id
            }) 
        }
        // if (prevState.resumeid === null) {
        //     this.setState({
        //         resumeid: this.props.resumeid_details_view
        //     })
        // }
    }



    handleEdit = (e) => {
        e.preventDefault();
        this.setState({
            edit: true,
            view: false,
            isDisabled: '',
            objective_edit: this.state.objective,
        })
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            edit: false,
            view: true
        })
    }

    notifySuccess = () => {
        toast("Success!", { autoClose: 5000 });
    };

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }

    checkMandatoryFields = () => {
        // const objective = this.state.objective;
        const objectiveEdit = this.state.objective_edit;
        // console.log(objective, objectiveEdit)
        
            objectiveEdit !== '' ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                this.setState({
                    ...this.state,
                    isDisabled: 'disabled'
                })
            )
        
    }

    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true
        })
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false
        })
    }

    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            id: this.state.objective_id,
            objective: this.state.objective_edit
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/GetObjective.php', qs.stringify(data), config)
            // .then(res => console.log(res))
            .then(() => this.setState({
                ...this.state,
                view: true,
                edit: false,
                add_new: false,
                objective: this.state.objective_edit,
            }))
            .then(() => this.endBtnLoader())
            .then(() => this.notifySuccess())
    }



    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleEdit = this.handleEdit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);

        // console.log(this.state)
        // console.log(this.props.objective)
        return (
            <div className="card summary mb-4">
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <div className="summary-data">
                            <div className="card-title mb-4">
                                <div className="row">
                                    <div className="col-md-4 col-sm-12">
                                        <h5 className="text-primary">
                                            <img src={IconRocket} alt="rocket" height="24" /> Profile
                                        </h5>
                                    </div>
                                </div>

                                {this.state.view && this.state.objective !== null && 
                                    <React.Fragment>
                                        {this.state.objective === null &&
                                            <div className="text-center">
                                                <EmptyState />
                                                <button onClick={this.handleAdd} className="btn btn-primary"><i className="fa fa-plus-circle"></i>&nbsp;Add New</button>
                                            </div>
                                        }
                                        {this.state.objective !== null &&
                                            <React.Fragment>
                                                <div className="d-flex">
                                                    {/* {console.log(this.state.objective_id)} */}
                                                    <div className="w-100">
                                                        <TextareaAutosize className="form-control view-textarea p-0 border-0 bg-white" defaultValue={isNull(this.state.objective)} readOnly="readonly"/>
                                                    </div>
                                                    <div className="ml-auto text-md-right text-sm-left flex-shrink-1">
                                                        <button onClick={this.handleEdit} className="btn btn-link-edit p-0">
                                                            <img src={IconEdit} alt="edit" height="20"/>
                                                        </button>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        }
                                    </React.Fragment>
                                }

                                {this.state.edit &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="summary">Write your objective</label>
                                                <textarea onChange={this.handleChangeEdit} name="objective_edit" className="form-control" cols="30" rows="5" placeholder="Your objective" required="required" defaultValue={this.state.objective_edit}></textarea>
                                            </div>
                                        </div>
                                        <button onClick={this.handleSubmit} className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }

                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-12">
                                                <label htmlFor="summary">Write your objective</label>
                                                <textarea onChange={this.handleChangeEdit} name="objective_edit" className="form-control" cols="30" rows="5" placeholder="Your objective">{this.state.objective_edit}</textarea>
                                            </div>
                                        </div>
                                        <button onClick={this.handleSubmit} className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </div>
                    )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    // resumeid_details_view: state.VIEW_RES_REDU.resumeid_details_view
})

export default connect(mapStateToProps, null )(Objective);