import React, { Component } from 'react';
import { removeLoaders } from './CommonFunctions';
import Footer from './Footer';
import MetaTags from 'react-meta-tags';

class AboutUs extends Component {
    componentDidMount() {
        removeLoaders();
    }
    
    render() {
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>About Us - Resumion</title>
                        <meta property="og:title" content="About Us - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>

                <div className='container'>
                    <div className="page-title text-center mb-4 mt-5">
                        <h3 className="text-center">About Us</h3>
                    </div>
                    <div className='row'>

                        <div className="d-flex justify-content-center mb-5">
                            <div className="col-md-8 col-lg-8">
                                <div className="card">
                                    <div className="card-body">
                                        {/* <p>
                                        The idea was introduced in 2018 by a couple of enthusiastic guys who found it really hard in their daily office routine works to experiment with various technologies with their own ideas. Starting with some simple trials projects, they happened to create the site in 2019 called resumion.com
                                        </p>
                                        <p>
                                        Creating an online resume is not a new one and the site is relatively new in the race of its kind. Yet it’s easier to be used as an online resume and cover letter builder helping  job hunters, professionals, and students become their own best candidate during their job search.
                                        </p> */}

                                        <p>
                                            Welcome to our resume builder application! Our platform is designed to make it easy for you to create a professional resume and cover letter that will help you stand out to potential employers. Whether you're just starting out in your career or you're a seasoned professional, our user-friendly web application can help you create a polished and effective resume.
                                        </p>
                                        <p>
                                        One of the great things about our application is that it's completely free to use. You don't need to pay any fees or sign up for any subscriptions to create your resume. We believe that everyone should have access to the tools they need to succeed, and we're proud to offer a high-quality resume builder at no cost to our users.
                                        </p>
                                        <p>
                                        Of course, we also offer a paid subscription for users who want to take their resume-building to the next level. With a premium subscription, you'll have access to additional features and templates that can help you create an even more impressive resume. However, even if you choose to stick with the free version of our platform, we're confident that you'll be able to create a resume that will impress potential employers.
                                        </p>
                                        <p>
                                        We've designed our resume builder to be as easy to use as possible. You don't need to have any special skills or knowledge to create a great resume with our platform. Our step-by-step process will guide you through the process of creating a professional-looking resume, and our intuitive interface makes it easy to customize your resume to suit your needs.
                                        </p>
                                        <p>
                                        At our core, we believe that everyone deserves the opportunity to succeed in their chosen career. We know that the job market can be competitive, and we're committed to providing our users with the tools they need to stand out from the crowd. We're proud to offer a resume builder that is accessible, user-friendly, and effective. We hope that you'll find our platform helpful as you pursue your professional goals.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}
 
export default AboutUs;