import React, { Component } from 'react';
import { IconTrash } from '../icon/index';
import { removeLoaders, getUseridFromSession, getAvatarFromSession, updateAvatarInSession } from './CommonFunctions';
import { getAvatar, isLoggedIn } from '../actions/CommonActions';
import BtnLoader from '../BtnLoader';
import axios from 'axios';
import qs from 'querystring';
import 'react-image-crop/dist/ReactCrop.css';
import ReactCrop from 'react-image-crop';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { IconAvatar } from '../icon/index';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Footer from './Footer';
import MetaTags from 'react-meta-tags';

// Google Analytics
import ReactGA from "react-ga4";
ReactGA.initialize('G-QJGG6X45S5');
ReactGA.send({ hitType: "pageview", page: window.location.pathname, title: document.title });

class AccountSettings extends Component {
    state = {
        src: null,
        croppedImageUrl: null,
        crop: {
            unit: '%',
            width: 100,
            height: 100,
            aspect: 1 / 1
        },
        minWidth: {

        },
        upload_container: false,
        isDisabled: 'disabled',
        isDisabledUpload: 'disabled',
        userid: null,
        btn_loader: false,
        oldpassword: '',
        newpassword: '',
        password_form: false,
        invalidCurrentPassword: false,
        sameAsOldPassword: false
    };

    componentDidMount() {
        removeLoaders();
        // console.log(getUseridFromSession())
        this.props.getAvatar()
        this.setState({
            userid: getUseridFromSession()
        })
    }

    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    checkMandatoryFields = () => {
        const oldPassword = this.state.oldpassword.length;
        const newPassword = this.state.newpassword.length;
        {
            oldPassword !== 0 && newPassword !== 0 && newPassword > 5 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        }
    }

    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled',
            isDisabledUpload: 'disabled',
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled',
            isDisabledUpload: 'disabled'
        })
    }

    handleDelete = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete photo?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    userid: this.state.userid,
                    delete: 1
                }
                const config = {
                    "Content-Type": "application/json"
                }
                axios.post('https://resumion.com/apiv2/UploadAvatar.php', qs.stringify(data), config)
                    .then(res => res.success)
                    .then(() => this.notifyDelete())
                    .then(() => updateAvatarInSession(''))
                    // .then(() => getAvatar(null))
                    .then(() => this.props.isLoggedIn())
                // .then(() => localStorage.setItem("avatar", JSON.stringify([''])))
            }
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            img: this.state.base64Image,
            userid: this.state.userid
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/UploadAvatar.php', qs.stringify(data), config)
            // .then(res => console.log(res.data.data[0].imagename))
            .then(res => res.data.data[0].imagename)

            .then(res => updateAvatarInSession(res))
            .then(() => this.props.isLoggedIn())

            .then(() => this.endBtnLoader())
            .then(() => {
                this.setState({
                    upload_container: false,
                    src: null
                })
            })
            .then(() => this.notify())
    }

    handleSubmitChangePassword = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            userid: this.state.userid,
            oldpassword: this.state.oldpassword,
            newpassword: this.state.newpassword
        }
        const config = {
            "Content-Type": "application/json"
        }
        axios.post('https://resumion.com/apiv2/ChangePassword.php', qs.stringify(data), config)
            .then(res => res.data)
            // .then(res => console.log(res))

            .then(res => {
                if (res.message === "1") {
                    this.setState({
                        invalidCurrentPassword: true,
                        sameAsOldPassword: false
                    })
                } else if (res.message === "2") {
                    this.setState({
                        sameAsOldPassword: true,
                        invalidCurrentPassword: false
                    })
                } else if (res.success === 1) {
                    this.setState({
                        password_form: false,
                        sameAsOldPassword: false,
                        invalidCurrentPassword: false,
                        oldpassword: '',
                        newpassword: ''
                    }, () => this.notify())
                }
            })
            .then(() => this.endBtnLoader())
        // .then(() => this.notify())
    }

    onSelectFile = e => {
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ src: reader.result })
            );
            this.setState({
                isDisabledUpload: ''
            })
            reader.readAsDataURL(e.target.files[0]);
            // this.fileInput.current.files[0].name
        }
    };

    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop);
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                "newFile.jpeg"
            );
            this.setState({ croppedImageUrl });
        }
    }

    handleUpload = (e) => {
        e.preventDefault();
        this.setState({
            upload_container: true,
            password_form: false
        })
    }

    handleFormOpen = (e) => {
        e.preventDefault();
        this.setState({
            upload_container: false,
            password_form: true,
            croppedImageUrl: null,
            isDisabledUpload: 'disabled',
            src: null
        })
    }

    handleFormClose = (e) => {
        e.preventDefault();
        this.setState({
            password_form: false,
            sameAsOldPassword: false,
            invalidCurrentPassword: false,
            oldpassword: '',
            newpassword: ''
        }, () => this.checkMandatoryFields())
    }

    handleUploadCancel = (e) => {
        e.preventDefault();
        // this.startBtnLoader();
        this.setState({
            upload_container: false,
            croppedImageUrl: null,
            src: null,
        })
    }



    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext("2d");

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        // As Base64 string
        const base64Image = canvas.toDataURL('image/jpeg');
        // console.log(base64Image)
        this.setState({
            base64Image: base64Image
        })

    }

    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }

    render() {
        // console.log(this.state)
        const { crop, croppedImageUrl, src } = this.state;
        this.handleChange = this.handleChange.bind(this);
        this.handleFormOpen = this.handleFormOpen.bind(this);
        this.handleFormClose = this.handleFormClose.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleSubmitChangePassword = this.handleSubmitChangePassword.bind(this);

        if (this.props.user_credentials.data !== undefined) {
            var { first_name, last_name, title } = this.props.user_credentials.data
        }

        const success_redux = this.props.user_credentials.success
        if (localStorage.getItem("user") !== null) {
            // Get success value from session storage
            var session_success = JSON.parse(localStorage.getItem("user"))[3]
        }
        // console.log(this.props.details_view.objective)
        // console.log(success_redux, session_success)
        if (success_redux !== 1 && session_success !== 1) {
            return (
                <Redirect to='/' />
            )
        }
        // console.log(this.props.user_credentials.data.first_name)
        // console.log(this.props)
        return (
            <React.Fragment>
                <div className="wrapper">
                    <MetaTags>
                        <title>Account Settings - Resumion</title>
                        <meta property="og:title" content="Account Settings - Resumion" />
                        <meta property="og:image" content="https://resumion.com/images/og_banner1.png" />
                    </MetaTags>
                </div>
                <div className="page-title text-center mt-5 mb-4">
                    <h4>Account Settings</h4>
                    <p className="text-secondary">Here you can change your photo and password.</p>
                </div>
                <div className="d-flex justify-content-center mb-5">
                    <div className="col-sm-8 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <div className="d-flex flex-column align-items-center">
                                    <div className="flex-fill text-center">
                                        <img className="rounded-circle mb-3" src={this.props.avatar !== '' && this.props.avatar !== null ? (
                                            'http://resumion.com/images/users/' + this.props.avatar
                                        ) : (IconAvatar)} alt="avatar" height="100" />

                                        <h5 className="mb-1">{first_name + " " + last_name}</h5>
                                        <p>{title}</p>
                                    </div>
                                    <div className="flex-fill">
                                        {src && (
                                            <ReactCrop
                                                src={src}
                                                crop={crop}
                                                onImageLoaded={this.onImageLoaded}
                                                onComplete={this.onCropComplete}
                                                onChange={this.onCropChange}
                                            />
                                        )}

                                        {this.state.upload_container === true ? (
                                            <React.Fragment>
                                                <input className="btn btn-default p-0 mb-3" type="file" onChange={this.onSelectFile} />
                                                {/* <small className="d-block mb-3">JPG or PNG. Max size of 800K</small> */}
                                                {croppedImageUrl &&
                                                    <img alt="Crop" style={{ maxWidth: "100%" }} src={croppedImageUrl} />
                                                }
                                                <div className="d-flex justify-content-center">
                                                    <button className="btn btn-primary mr-2" onClick={this.handleSubmit.bind(this)} disabled={this.state.isDisabledUpload}>
                                                        {this.state.btn_loader ? (<BtnLoader />) : (<span>Upload</span>)}
                                                    </button>
                                                    <button className="btn btn-outline-dark" onClick={this.handleUploadCancel.bind(this)}>Cancel</button>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                                <React.Fragment>
                                                    <button onClick={this.handleUpload.bind(this)} className="btn btn-primary">Upload new picture</button>
                                                    {this.props.avatar !== '' && this.props.avatar !== null &&
                                                        <button onClick={this.handleDelete} className="btn btn-outline-dark ml-2">Delete</button>
                                                    }
                                                </React.Fragment>

                                            )}
                                    </div>
                                </div>
                                <hr className="mt-4 mb-4" />
                                <div className="d-flex flex-column">
                                    <div>
                                        <label className="d-block lg mb-2 text-dark">Account Password</label>
                                        {this.state.password_form === false && <button onClick={this.handleFormOpen} className="btn btn-outline-dark w-100 mb-3">Change password</button>}
                                    </div>

                                    {this.state.password_form &&
                                        <form className="personal-details-edit" onSubmit={this.handleSubmitChangePassword}>
                                            <div className="form-row">
                                                <div className="form-group col-md-6 mb-2">
                                                    <label htmlFor="oldpassword">Current password</label>
                                                    <input onChange={this.handleChange} type="password" className="form-control" name="oldpassword" placeholder="xxxxxxxx" value={this.state.oldpassword} />
                                                    {this.state.invalidCurrentPassword &&
                                                        <small className="form-text text-danger">Invalid current password</small>
                                                    }
                                                    {this.state.sameAsOldPassword &&
                                                        <small className="form-text text-danger">New password should be dirrerent from current password</small>
                                                    }
                                                </div>
                                                <div className="form-group col-md-6 mb-2">
                                                    <label htmlFor="newpassword">New password</label>
                                                    <input onChange={this.handleChange} type="password" className="form-control" name="newpassword" placeholder="xxxxxxxx" value={this.state.newpassword} />
                                                    <small className="form-text text-muted">Min 6 characters</small>
                                                </div>
                                                <div className="form-group col-12">
                                                    <button className="btn btn-success mr-2" disabled={this.state.isDisabled}>
                                                        {this.state.btn_loader ? (<BtnLoader />) : (<span>Submit</span>)}
                                                    </button>
                                                    <button onClick={this.handleFormClose} className="btn btn-outline-dark">
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials,
    avatar: state.COM_REDU.avatar,
})

export default connect(mapStateToProps, { getAvatar, isLoggedIn })(AccountSettings);