import React, { Component } from 'react';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isLoggedIn } from '../actions/CommonActions';
import { IconEnvelopeCircle } from '../icon/index';
import { removeLoaders } from './CommonFunctions';
import PageLoader from '../PageLoader';

import axios from 'axios';
import qs from 'querystring';

class EmailVerification extends Component {
    state = {
        PageLoader: true,
        email_confirmation: false,
        email_already_verified: false
    }
    componentDidMount() {
        this.props.isLoggedIn();
        // let userid = getUseridFromSession()
        removeLoaders();
        // const userid = this.props.match.params.userid;
        let userid = this.props.location.hash.slice(1);

        axios.get('https://resumion.com/api/EmailVerification.php?userid='+userid)
        .then(res => res.data)
        .then(res => res.success === 1 && res.message === 0 ?
            this.setState({
                PageLoader: false,
                email_confirmation: true
            }) : res.success === 1 && res.message === 1 ?
                this.setState({
                    PageLoader: false,
                    email_already_verified: true
                })
                : null
        )
        .catch(function (error) {
          console.log(error);
        }) 
    }

    componentDidUpdate() {
        
    }

    render() {
        // console.log(this.state)
        // console.log(this.props.match.params.userid)
        // console.log(getUseridFromSession())

        // const success = this.props.user_credentials.success
        // if (success === 1) {
        //     var { first_name, last_name, userid } = this.props.user_credentials.data
        // }

        return (
            <React.Fragment>
                <div className="mb-5">
                    <div className="d-flex justify-content-center text-center">
                        <div className="bg-white p-5 mt-5 shadow-sm rounded-lg">
                            {this.state.PageLoader && <PageLoader/> }
                            {this.state.email_confirmation &&
                                <React.Fragment>
                                    <img src={IconEnvelopeCircle} alt="Success" height="84" />
                                    <h5 className="mt-4">Congratulations</h5>
                                    <p>Your email address has been verified successfully.</p>
                                    <Link to='/builder' className="btn btn-primary">Create resume now</Link>
                                </React.Fragment>
                            }
                            {this.state.email_already_verified &&
                                <React.Fragment>
                                    <img src={IconEnvelopeCircle} alt="Success" height="84" />
                                    <h5 className="mt-4">Already Verified</h5>
                                    <p>Your email address is already verified.</p>
                                    <Link to='/builder' className="btn btn-primary">Create resume now</Link>
                                </React.Fragment>
                            }
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    user_credentials: state.COM_REDU.user_credentials
})

export default connect(mapStateToProps, { isLoggedIn })(EmailVerification);