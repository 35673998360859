import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CookiesDialog extends Component {
    state = {
        ckag: ''
    }

    componentDidMount() {
        if (localStorage.getItem("ckag") === null) {
            localStorage.setItem("ckag", JSON.stringify(['0']))
        }
        this.setState({
            ckag: JSON.parse(localStorage.getItem("ckag"))[0]
        })
    }

    handleCookies = (e) => {
        e.preventDefault();
        localStorage.setItem("ckag", JSON.stringify(['1']))
        this.setState({
            ckag: "1"
        })
    }
    
    render() {
        // console.log(this.state)
        this.handleCookies = this.handleCookies.bind(this)
        return (
            <React.Fragment>
                {this.state.ckag !== "1" &&
                    <div className="card bg-dark cookies-dialog position-fixed">
                        <div className="card-body p-4">
                            <p className="text-white">
                                We use cookies to maintain login sessions, analytics and to improve your experience on our website. 
                                By continuing to use our site, you accept our use of cookies, <Link to='/terms-and-conditions'><strong className="text-white"><u>Terms & Conditions</u></strong></Link> and <Link to='/privacy-policy'><strong className="text-white"><u>Privacy Policy</u></strong></Link>.
                            </p>
                            <button onClick={this.handleCookies} className="btn btn-primary">Accept</button>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }
}
 
export default CookiesDialog;