import React, { Component } from 'react';
import { Link } from "react-router-dom";
import axios from 'axios';

class Promotion extends Component {

    state = {
        banner: '',
        offerTitle: '',
        targetURL: ''
    }

    async componentDidMount() {
        try {
            const response = await axios.get(`https://resumion.com/apiv2/Promotion.json`);
            this.setState({
                banner: response.data.data.banner,
                targetURL: response.data.data.targetURL,
            })
            // console.log(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }
    
    render() {
        return (
            <React.Fragment>
                <div className='container-fluid text-center bg-violet'>
                    {this.state.banner !== '' &&
                        <Link to={this.state.targetURL}>
                            <img className='img-fluid banner-promotion' src={this.state.banner} alt="50% discount" />
                        </Link>
                    }
                </div>
            </React.Fragment>
        );
    }
}
 
export default Promotion;