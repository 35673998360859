import React, { Component } from 'react';
import { BeatLoader } from 'react-spinners';

class BtnLoader extends Component {
    state = {
        loading: true
    }
    render() {
        return (
            <div className="m-auto">
                <div className='sweet-loading'>
                    <BeatLoader
                        sizeUnit={"px"}
                        size={10}
                        color={'#ffffff'}
                        loading={this.state.loading}
                    />
                </div>
            </div>
        );
    }
}

export default BtnLoader;