import React, { Component } from 'react';
import LayoutLoader from '../../LayoutLoader';
import { connect } from 'react-redux';
import axios from 'axios';
import qs from 'querystring';
import BtnLoader from '../../BtnLoader';
import EmptyState from '../../components/EmptyState'
import { IconFilesTc, IconTrash, IconPlusCircleWhite } from '../../icon/index';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { 
    IconEdit,
    IconTrashSolid
 } from '../../icon/index';

class References extends Component {
    state = {
        references: null,
        view: true,
        edit: false,
        add_new: false,
        reference_name: '',
        relationship: '',
        company_name: '',
        phone: '',
        email: '',
        address: '',
        reference_id_edit: null,
        reference_name_edit: '',
        relationship_edit: '',
        company_name_edit: '',
        phone_edit: '',
        email_edit: '',
        address_edit: '',
        resumeid: null,
        layout_loader: true,
        isDisabled: 'disabled',

        btn_loader: false
    }


    notify = () => {
        toast("Success!", { autoClose: 5000 });
    };

    notifyDelete = () => {
        toast("Deleted", { autoClose: 5000 });
    };

    componentDidMount() {
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.references === null && this.props.references !== null) {
            this.setState({
                ...this.state,
                references: this.props.references
            })
            if (prevState.layout_loader === true) {
                this.setState({
                    ...this.state,
                    layout_loader: false
                })
            }
        }
        if (prevState.resumeid === null) {
            this.setState({
                resumeid: this.props.resumeid
            })
        }
    }

    addSkill = (e) => {
        e.preventDefault();
        this.props.insertEmploymentForm();
    }
    handleChange = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFields())
    }
    handleChangeEdit = (e) => {
        e.preventDefault();
        this.setState({
            [e.target.name]: e.target.value
        }, () => this.checkMandatoryFieldsEdit())
    }
    checkMandatoryFields = () => {
        const nameLength = this.state.reference_name.length;
        const relationshipLength = this.state.relationship.length;
        const companyLength = this.state.company_name.length;
        const phoneLength = this.state.phone.length;

        
            nameLength !== 0 && relationshipLength !== 0 && companyLength !== 0 && phoneLength !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )

        
        // console.log(this.state.reference_name.length)
    }

    checkMandatoryFieldsEdit = () => {
        
            this.state.reference_name_edit.length !== 0 || this.state.relationship_edit.length !== 0 || this.state.company_name_edit.length !== 0 || this.state.phone_edit.length !== 0 !== 0 ? (
                this.setState({
                    ...this.state,
                    isDisabled: ''
                })
            ) : (
                    this.setState({
                        ...this.state,
                        isDisabled: 'disabled'
                    })
                )
        
    }
    startBtnLoader = () => {
        this.setState({
            btn_loader: true,
            isDisabled: 'disabled'
        })
    }
    endBtnLoader = () => {
        this.setState({
            btn_loader: false,
            isDisabled: 'disabled'
        })
    }
    handleAdd = (e) => {
        e.preventDefault();
        this.setState({
            view: false,
            add_new: true
        }, () => this.endBtnLoader())
    }
    handleCancel = (e) => {
        e.preventDefault();
        this.setState({
            view: true,
            edit: false,
            add_new: false,
        }, () => this.checkMandatoryFields())
    }


    handleSubmit = (e) => {
        e.preventDefault();
        this.startBtnLoader();
        const data = {
            resumeid: this.state.resumeid,
            reference_name: this.state.reference_name,
            relationship: this.state.relationship,
            company_name: this.state.company_name,
            phone: this.state.phone,
            email: this.state.email,
            address: this.state.address,
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/AddReference.php', qs.stringify(data), config)
            .then(res => res.data.data[0].reference[0])
            .then(res => this.setState({
                view: true,
                edit: false,
                add_new: false,
                reference_name: '',
                relationship: '',
                company_name: '',
                phone: '',
                email: '',
                address: '',
                references: [...this.state.references, res]
            }), () => this.checkMandatoryFields())
            .then(() => this.notify())
            .then(() => this.endBtnLoader())
    }

    handleUpdate = (e) => {
        e.preventDefault();
        this.startBtnLoader();

        const data = {
            id: this.state.reference_id_edit,
            reference_name: this.state.reference_name_edit,
            relationship: this.state.relationship_edit,
            company_name: this.state.company_name_edit,
            phone: this.state.phone_edit,
            email: this.state.email_edit,
            address: this.state.address_edit,
        }
        const config = {
            "Content-Type": "application/json"
        }

        axios.post('https://resumion.com/apiv2/GetReference.php', qs.stringify(data), config)
            .then(res => res.data.data[0].reference[0])
            // .then(res => console.log(res))
            .then(res => {
                // Getting the index number of edit item
                const index = this.state.references.map(reference => reference.id === data.id)
                const indices = index.reduce(
                    (out, bool, index) => bool ? out.concat(index) : out,
                    []
                )
                // console.log(indices)         
                let references = [...this.state.references];
                let reference = { ...references[indices] };
                reference = res;
                references[indices] = reference;
                this.setState({
                    view: true,
                    edit: false,
                    references: references
                });
                this.endBtnLoader();
                this.notify();
            })
    }

    handleDelete = (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-danger mr-2',
                cancelButton: 'btn btn-outline-dark',
            },
            buttonsStyling: false
        })
        swalWithBootstrapButtons.fire({
            title: 'Confirm Delete',
            text: "Are you sure to delete this?",
            imageUrl: IconTrash,
            imageWidth: 84,
            imageHeight: 84,
            imageAlt: 'Custom image',
            showCancelButton: true,
            confirmButtonText: 'Delete'
        }).then((result) => {
            if (result.value) {
                const data = {
                    id: id,
                    type: 'reference'
                }
                axios.get('https://resumion.com/apiv2/Deleteitem.php?' + qs.stringify(data))
                    // .then(res => console.log(res.data))
                    .then(() => this.notifyDelete())
                const references = this.state.references.filter(reference => reference.id !== id);
                this.setState({ references: references });
            }
        })
    }

    handleEdit = (id) => {
        // console.log(id)
        const references = this.state.references.filter(reference => reference.id === id);
        this.setState({
            view: false,
            edit: true,

            reference_name_edit: references[0].reference_name,
            reference_id_edit: references[0].id,
            relationship_edit: references[0].relationship,
            company_name_edit: references[0].company_name,
            phone_edit: references[0].phone,
            email_edit: references[0].email,
            address_edit: references[0].address,

        }, () => this.checkMandatoryFieldsEdit())
    }
    render() {
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleAdd = this.handleAdd.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        // this.handleEdit = this.handleEdit.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);

        // console.log(this.state)
        // console.log(this.props.references)

        if (this.state.references !== null) {
            var references = this.state.references.map((reference, i, arr) => {
                return (
                    <React.Fragment key={reference.id}>
                        <div className="col-md-12">
                            <div className="d-flex">
                                <div className="flex-fill">
                                    <h6 className="mb-1">{reference.reference_name} - {reference.relationship}</h6>
                                    <p className="mb-2 font-weight-medium-bold">{reference.company_name}</p>
                                    <ul className="list-group references">
                                        <li className="list-group-item border-0 p-0"><label className="text-dark font-weight-medium-bold">Phone:</label> {reference.phone}</li>
                                        {reference.email !== '' &&
                                            <li className="list-group-item border-0 p-0"><label className="text-dark font-weight-medium-bold">Email:</label> {reference.email}</li>
                                        }
                                        {reference.address !== '' &&
                                        <li className="list-group-item border-0 p-0"><label className="text-dark font-weight-medium-bold">Address:</label> {reference.address}</li>
                                        }
                                    </ul>
                                </div>
                                <div className="flex-fill text-right">
                                    <button onClick={this.handleEdit.bind(this, reference.id)} className="btn btn-link-edit p-0 mr-2"><img src={IconEdit} alt="edit" height="20"/></button>
                                    <button onClick={this.handleDelete.bind(this, reference.id)} className="btn btn-link-delete p-0"><img src={IconTrashSolid} alt="delete" height="20"/></button>
                                </div>
                            </div>
                        </div>
                        {arr.length !== i + 1 &&
                            <div className="col-12">
                                <hr className="mt-3 mb-3" />
                            </div>
                        }
                    </React.Fragment>
                )
            })
        }
        // console.log(this.state.languages)

        return (
            <div className="card mb-4">
                {this.state.layout_loader === true ? (
                    <div className="col-sm-8 m-5">
                        <LayoutLoader />
                    </div>
                ) : (
                        <React.Fragment>
                            <div className="card-title mb-4">
                                <div className="d-flex justify-content-start">
                                    <div className="mr-2"><img src={IconFilesTc} alt="cap" width="24" /></div>
                                    <div>
                                        <h5 className="text-primary">References</h5>
                                        <p className="text-muted mb-0">Add your professional/ personal references.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-0">
                                {this.state.view && this.state.references !== null && 
                                    <React.Fragment>
                                        <div className="row">
                                            {references}
                                            {this.state.references !== null && this.state.references.length === 0 &&
                                                <div className="col text-center">
                                                    <EmptyState />
                                                    <button onClick={this.handleAdd} className="btn btn-primary"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                                </div>
                                            }
                                        </div>
                                        {this.state.references !== null && this.state.references.length !== 0 &&
                                            <button onClick={this.handleAdd} className="btn btn-primary mt-4"><img className="mr-1 align-text-bottom" src={IconPlusCircleWhite} alt="+" height="16" /> Add New</button>
                                        }
                                    </React.Fragment>
                                }
                                {this.state.edit &&
                                    <form onSubmit={this.handleUpdate}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="reference_name_edit">Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="reference_name_edit" placeholder="e.g. John Doe" value={this.state.reference_name_edit} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="relationship_edit">Relationship <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="relationship_edit" placeholder="e.g. Cousin" value={this.state.relationship_edit} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="company_name_edit">Company <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="company_name_edit" placeholder="e.g. Apple" value={this.state.company_name_edit} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="email_edit">Email</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="email_edit" placeholder="e.g. smith@email.com" value={this.state.email_edit} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="phone_edit">Phone <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="phone_edit" placeholder="e.g. 601-370-5626" value={this.state.phone_edit} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="address_edit">Address</label>
                                                <input onChange={this.handleChangeEdit} type="text" className="form-control" name="address_edit" placeholder="e.g. John Doe" value={this.state.address_edit} />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                                {this.state.add_new &&
                                    <form onSubmit={this.handleSubmit}>
                                        <div className="form-row">
                                            <div className="form-group col-md-6">
                                                <label htmlFor="reference_name">Name <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="reference_name" placeholder="e.g. John Doe" value={this.state.reference_name} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="relationship">Relationship <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="relationship" placeholder="e.g. Cousin" value={this.state.relationship} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="company_name">Company <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="company_name" placeholder="e.g. Apple" value={this.state.company_name} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="email">Email</label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="email" placeholder="e.g. smith@email.com" value={this.state.email} />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="phone">Phone <p className="text-danger d-inline-block m-0"><abbr title="This field is required">*</abbr></p></label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="phone" placeholder="e.g. 601-370-5626" value={this.state.phone} required />
                                            </div>
                                            <div className="form-group col-md-6">
                                                <label htmlFor="address">Address</label>
                                                <input onChange={this.handleChange} type="text" className="form-control" name="address" placeholder="e.g. John Doe" value={this.state.address} />
                                            </div>
                                        </div>
                                        <button className="btn btn-primary mr-1" disabled={this.state.isDisabled}>
                                            {this.state.btn_loader ? (<BtnLoader />) : (<span>Save</span>)}
                                        </button>
                                        <button onClick={this.handleCancel} type="button" className="btn btn-outline-dark">Cancel</button>
                                    </form>
                                }
                            </div>
                        </React.Fragment>
                    )
                }
            </div>
        );
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, null)(References);
